import {
	addDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import {
	IChangeLog,
	IChangeLogAuthor,
	IChangeLogBase,
	IChangeLogChange,
} from "../../types/ChangeLog.types";
import { IOrderDto, IProceedingDto } from "../../types/Order.types";
import { IOrderPartsDto, IPartEstimateDto } from "../../types/Parts.types";
import { IOrderServiceDto, IServiceDto } from "../../types/Services.types";
import { updateItemInArrayById } from "../../utils/helpers/parsers";
import { compareObjects } from "../../utils/helpers/processors";
import { db, ordersRef } from "../firestore";
import { addChangeLogToOrder } from "./changeLogs";
import { getServiceDataById } from "./services";
import { getTemplateById } from "./templates";

// READ SECTION
async function getOrders(
	companyTaxId: string,
	isActive: boolean
): Promise<IOrderDto[]> {
	try {
		var q = query(
			ordersRef,
			where("isActive", "==", isActive),
			where("company", "==", companyTaxId),
			orderBy("createdDate", "asc")
		);
		var dataSnapshot = await getDocs(q);

		let orders = dataSnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				externalId: data.externalId,
				model: data.model,
				isActive: data.isActive,
				totalAmount: data.totalAmount,
				manufacturerFipeId: data.manufacturerFipeId,
				modelFipeId: data.modelFipeId,
				manufacturer: data.manufacturer,
				createdDate: data.createdDate.toDate(),
				linkedIds: data.linkedIds,
				plate: data.plate,
				year: data.year,
				serviceGroups: data.serviceGroups,
				services: data.services,
				parts: data.parts,
				fipeCode: data.fipeCode,
				type: data.type,
				proceedings: data.proceedings,
				modelAdjustmentFactor: data.modelAdjustmentFactor,
				company: data.company,
				createdBy: data.createdBy,
				sequentialId: data.sequentialId,
				changeLogs: data.changeLogs,
			} as IOrderDto;
		});
		return orders;
	} catch (error: any) {
		console.error(error);
	}
	return [];
}

async function getLastOrderIdByCompany(companyTaxId: string): Promise<number> {
	try {
		var q = query(
			ordersRef,
			where("company", "==", companyTaxId),
			limit(1),
			orderBy("createdDate", "desc")
		);
		var dataSnapshot = await getDocs(q);

		if (dataSnapshot.docs.length) {
			const data = dataSnapshot.docs[0].data();
			const lastId = data.sequentialId;
			return lastId;
		} else {
			return 0;
		}
	} catch (error: any) {
		console.error(error);
		throw error;
	}
}

async function getOrderByExternalId(
	companyTaxId: string,
	externalId: string
): Promise<IOrderDto[]> {
	try {
		var q = query(
			ordersRef,
			where("externalId", "==", externalId),
			where("company", "==", companyTaxId)
		);
		var dataSnapshot = await getDocs(q);

		let orders = dataSnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				externalId: data.externalId,
				model: data.model,
				isActive: data.isActive,
				totalAmount: data.totalAmount,
				manufacturer: data.manufacturer,
				manufacturerFipeId: data.manufacturerFipeId,
				modelFipeId: data.modelFipeId,
				createdDate: data.createdDate.toDate(),
				linkedIds: data.linkedIds,
				plate: data.plate,
				year: data.year,
				serviceGroups: data.serviceGroups,
				services: data.services,
				parts: data.parts,
				fipeCode: data.fipeCode,
				type: data.type,
				proceedings: data.proceedings,
				modelAdjustmentFactor: data.modelAdjustmentFactor,
				company: data.company,
				createdBy: data.createdBy,
				sequentialId: data.sequentialId,
				changeLogs: data.changeLogs,
			} as IOrderDto;
		});
		return orders;
	} catch (error: any) {
		console.error(error);
	}
	return [];
}

async function getOrderByExternalOrSequentialId(
	companyTaxId: string,
	id: string,
	idType: "sequential" | "external"
) {
	try {
		const parsedIdType =
			idType === "sequential" ? "sequentialId" : "externalId";
		var q = query(
			ordersRef,
			where("company", "==", companyTaxId),
			where(parsedIdType, "==", id),
			limit(1)
		);

		var dataSnapshot = await getDocs(q);

		if (dataSnapshot.docs.length) {
			const data = dataSnapshot.docs[0].data();
			return {
				...data,
				createdDate: data!.createdDate.toDate(),
			} as IOrderDto;
		}

		return undefined;
	} catch (error) {
		console.error(error);
	}
}

async function getOrder(id: string) {
	try {
		const docSnapshot = await getDoc(doc(db, Collections.orders, id));
		const data = docSnapshot.data();

		return {
			...data,
			createdDate: data!.createdDate.toDate(),
		} as IOrderDto;
	} catch (error) {
		console.error(error);
	}
}

// CREATE SECTION
async function addOrder(order: IOrderDto, author: IChangeLogAuthor) {
	try {
		const existingOrderwithExternalId =
			await getOrderByExternalOrSequentialId(
				order.company,
				order.externalId,
				"external"
			);

		if (existingOrderwithExternalId) {
			throw new Error("Já existe um orçamento com esse Id externo");
		}

		const existingOrderwithSequentialId =
			await getOrderByExternalOrSequentialId(
				order.company,
				order.sequentialId.toString(),
				"sequential"
			);

		if (existingOrderwithSequentialId) {
			throw new Error("Já existe um orçamento com esse Id numérico");
		}

		let orderWithoutId = (({ id, ...o }) => o)(order);

		const addedDoc = await addDoc(ordersRef, {
			...orderWithoutId,
		});

		const newChangeLog: IChangeLog = {
			targetIdentifier: order.sequentialId,
			scope: "order",
			date: new Date(),
			type: "create",
			author: author,
			changes: [],
		};

		await addChangeLogToOrder(newChangeLog, addedDoc.id);
	} catch (error: any) {
		console.error(error);
		throw error;
	}
}

// DELETE SECTION

// UPDATE SECTION
async function finishOrder(id: string, baseChangeLog: IChangeLogBase) {
	try {
		await updateDoc(doc(db, Collections.orders, id), { isActive: false });
		await addChangeLogToOrder({ ...baseChangeLog, changes: [] }, id);
	} catch (error: any) {
		console.error(error);
	}
}

async function reactivateOrder(id: string, baseChangeLog: IChangeLogBase) {
	try {
		await updateDoc(doc(db, Collections.orders, id), { isActive: true });
		await addChangeLogToOrder({ ...baseChangeLog, changes: [] }, id);
	} catch (error: any) {
		console.error(error);
	}
}

async function addServiceToOrder(
	orderId: string,
	proceedingId: string,
	serviceId: string
) {
	try {
		const order = await getOrder(orderId);
		const service = await getServiceDataById(serviceId);

		order?.proceedings.forEach((p: IProceedingDto) => {
			const serviceAlreadyExists = p.services?.some(
				(s: IServiceDto) => s.id === serviceId
			);

			if (serviceAlreadyExists) {
				throw new Error(
					`Serviço ${service!.description} já cadastrado no orçamento`
				);
			}
		});

		let proceeding = order?.proceedings.find(
			(p: IProceedingDto) => p.id === proceedingId
		);

		proceeding?.services?.push(service! as IOrderServiceDto);

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: order?.proceedings,
		});
	} catch (error: any) {
		throw error;
	}
}

async function removeServiceFromOrderProceeding(
	orderId: string,
	service: IServiceDto,
	proceeding: IProceedingDto
) {
	try {
		const order = await getOrder(orderId);

		let orderProceeding = order?.proceedings.find(
			(p: IProceedingDto) => p.id === proceeding.id
		);

		const newProceedingServices = orderProceeding!.services.filter(
			(s: IServiceDto) => s.description !== service.description
		);

		orderProceeding!.services = newProceedingServices;

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: order?.proceedings,
		});
	} catch (error: any) {
		console.error(error);
	}
}

async function addProccedingToOrder(
	orderId: string,
	orderProceeding: IProceedingDto
) {
	try {
		const order = await getOrder(orderId);

		const proceedingAlreadyExists = order?.proceedings.some(
			(o: IProceedingDto) => o.id === orderProceeding.id
		);

		if (proceedingAlreadyExists) {
			throw new Error("Procedimento já cadastrado no orçamento");
		}

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: [
				...order?.proceedings!,
				{ ...orderProceeding, value: Number(orderProceeding.value) },
			],
		});
	} catch (error) {
		console.error(error);
	}
}

async function updateProceedingInOrder(
	orderId: string,
	orderProceedingId: string,
	newData: IProceedingDto
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderProceeding = order?.proceedings.find(
			(o: IProceedingDto) => o.id === orderProceedingId
		);

		existentOrderProceeding!.description = newData.description!;
		existentOrderProceeding!.value = Number(newData.value);

		const newProceedings = updateItemInArrayById(
			order!.proceedings,
			orderProceedingId,
			existentOrderProceeding!
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: newProceedings,
		});
		await new Promise((f) => setTimeout(f, 2000));
	} catch (error) {
		console.error(error);
	}
}

async function updatePartsEstimates(
	orderId: string,
	orderPartId: string,
	newEstimate: IPartEstimateDto,
	changeLogBase: IChangeLogBase,
	idToRemoveBestOption?: string
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderPart = order?.parts.find(
			(o: IOrderPartsDto) => o.id === orderPartId
		);

		let newEstimates = existentOrderPart?.estimates;
		let changes: IChangeLogChange[] = [];

		let existentEstimate = existentOrderPart?.estimates.find(
			(e: IPartEstimateDto) => e.id === newEstimate.id
		);

		if (!!existentEstimate) {
			changes = compareObjects(existentEstimate!, newEstimate);

			newEstimates = updateItemInArrayById(
				newEstimates!,
				existentEstimate.id,
				newEstimate
			);
		} else {
			newEstimates?.push(newEstimate);
		}

		if (!!idToRemoveBestOption) {
			const oldBestOption = newEstimates?.find(
				(e: IPartEstimateDto) => e.id === idToRemoveBestOption
			);

			newEstimates = updateItemInArrayById(
				newEstimates!,
				idToRemoveBestOption,
				{ ...oldBestOption!, isBestOption: false }
			);
		}

		existentOrderPart!.estimates = newEstimates!;

		const newParts = updateItemInArrayById(
			order!.parts,
			orderPartId,
			existentOrderPart!
		);

		const newChangeLog: IChangeLog = {
			targetIdentifier: changeLogBase.targetIdentifier,
			scope: changeLogBase.scope,
			date: changeLogBase.date,
			type: changeLogBase.type,
			author: changeLogBase.author,
			changes: changes,
		};

		await addChangeLogToOrder(newChangeLog, orderId);
		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: newParts,
		});
		await new Promise((f) => setTimeout(f, 1000));
	} catch (error) {
		console.error(error);
	}
}

async function upsertPartInOrder(
	orderId: string,
	orderPartId: string,
	newData: IOrderPartsDto,
	changeLogBase: IChangeLogBase
) {
	try {
		const order = await getOrder(orderId);
		let newParts: IOrderPartsDto[] = [];

		let existentOrderPart = order?.parts.find(
			(o: IOrderPartsDto) => o.id === orderPartId
		);

		let changes: IChangeLogChange[] = [];

		if (!!existentOrderPart) {
			changes = compareObjects(
				(({ estimates, ...p }) => p)(existentOrderPart!),
				(({ estimates, ...p }) => p)(newData)
			);

			existentOrderPart!.description = newData.description!;
			existentOrderPart!.status = newData.status!;

			if (!!newData.originalCode) {
				existentOrderPart.originalCode = newData.originalCode;
			}

			newParts = updateItemInArrayById(
				order!.parts,
				orderPartId,
				existentOrderPart!
			);
		} else {
			newParts = [...order!.parts, newData];
		}

		const newChangeLog: IChangeLog = {
			targetIdentifier: changeLogBase.targetIdentifier,
			scope: changeLogBase.scope,
			date: changeLogBase.date,
			type: changeLogBase.type,
			author: changeLogBase.author,
			changes: changes,
		};

		await addChangeLogToOrder(newChangeLog, orderId);
		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: newParts,
		});
		await new Promise((f) => setTimeout(f, 2000));
	} catch (error) {
		console.error(error);
	}
}

async function updatePartsBatch(orderId: string, newParts: IOrderPartsDto[]) {
	try {
		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: newParts,
		});
	} catch (error) {
		console.error(error);
	}
}

async function deletePartEstimate(
	orderId: string,
	orderPartId: string,
	idToRemove: string,
	baseChangeLog: IChangeLogBase
) {
	try {
		const order = await getOrder(orderId);

		let existentOrderPart = order?.parts.find(
			(op: IOrderPartsDto) => op.id === orderPartId
		);

		let newEstimates = existentOrderPart?.estimates;
		const existentEstimate = existentOrderPart!.estimates.find(
			(e: IPartEstimateDto) => e.id === idToRemove
		);

		const newChangeLog: IChangeLog = {
			author: baseChangeLog.author,
			date: baseChangeLog.date,
			scope: baseChangeLog.scope,
			type: baseChangeLog.type,
			targetIdentifier: `do fornecedor ${
				existentEstimate!.provider
			} da marca ${existentEstimate!.brand}`,
			changes: [],
		};

		newEstimates = existentOrderPart!.estimates.filter(
			(e: IPartEstimateDto) => e.id !== idToRemove
		);

		let newParts = order?.parts.filter(
			(o: IOrderPartsDto) => o.id !== orderPartId
		);

		existentOrderPart!.estimates = newEstimates!;

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: [...newParts!, existentOrderPart],
		});
		await addChangeLogToOrder(newChangeLog, orderId);
	} catch (error) {
		console.error(error);
	}
}

async function deleteOrderPart(
	orderId: string,
	orderPartId: string,
	baseChangeLog: IChangeLogBase
) {
	try {
		const order = await getOrder(orderId);

		const existentPart = order?.parts.find(
			(o: IOrderPartsDto) => o.id === orderPartId
		);
		let newParts = order?.parts.filter(
			(o: IOrderPartsDto) => o.id !== orderPartId
		);

		const newChangeLog: IChangeLog = {
			author: baseChangeLog.author,
			date: baseChangeLog.date,
			scope: baseChangeLog.scope,
			type: baseChangeLog.type,
			targetIdentifier: existentPart!.description,
			changes: [],
		};

		await updateDoc(doc(db, Collections.orders, orderId), {
			parts: [...newParts!],
		});
		await addChangeLogToOrder(newChangeLog, orderId);
	} catch (error) {
		console.error(error);
	}
}

async function deleteOrderProceeding(
	orderId: string,
	orderProceedingId: string
) {
	try {
		const order = await getOrder(orderId);

		let newProceedings = order?.proceedings.filter(
			(p: IProceedingDto) => p.id !== orderProceedingId
		);

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: [...newProceedings!],
		});
	} catch (error) {
		console.error(error);
	}
}

async function importTemplateIntoOrder(
	orderId: string,
	templateId: string,
	proceedingId: string
) {
	try {
		const order = await getOrder(orderId);
		const template = await getTemplateById(templateId);

		let proceedingServices = order?.proceedings.find(
			(p: IProceedingDto) => p.id === proceedingId
		)?.services;

		template!.services.forEach(async (ts: IServiceDto) => {
			const serviceAlreadyExists = proceedingServices?.some(
				(os: IOrderServiceDto) => ts.id === os.id
			);

			if (!serviceAlreadyExists) {
				proceedingServices?.push(ts as IOrderServiceDto);
			}
		});

		await updateDoc(doc(db, Collections.orders, orderId), {
			proceedings: order?.proceedings,
		});
	} catch (error) {
		console.error(error);
	}
}

export {
	addOrder,
	addProccedingToOrder,
	addServiceToOrder,
	deleteOrderPart,
	deleteOrderProceeding,
	deletePartEstimate,
	finishOrder,
	getLastOrderIdByCompany,
	getOrder,
	getOrderByExternalId,
	getOrderByExternalOrSequentialId,
	getOrders,
	importTemplateIntoOrder,
	reactivateOrder,
	removeServiceFromOrderProceeding,
	updatePartsBatch,
	updatePartsEstimates,
	updateProceedingInOrder,
	upsertPartInOrder,
};
