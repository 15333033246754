import { IServiceDto } from "../../../../types/Services.types";

export interface IServiceTemplateCreationForm {
	description: string;
	services: IServiceDto[];
}

export const emptyServiceTemplateForm: IServiceTemplateCreationForm = {
	description: "",
	services: [],
};
