import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { IMaskInput } from "react-imask";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const PhoneTextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
	function TextMaskCustom(props, ref) {
		const { onChange, ...other } = props;
		return (
			<IMaskInput
				{...other}
				mask="(00) 00000-0000"
				definitions={{
					"#": /[1-9]/,
				}}
				inputRef={ref}
				onAccept={(value: any) =>
					onChange({ target: { name: props.name, value } })
				}
				overwrite
			/>
		);
	}
);

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values: any) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				decimalSeparator=","
				fixedDecimalScale
				decimalScale={2}
				valueIsNumericString
				prefix="R$"
			/>
		);
	}
);

export default function FormattedInputs() {
	const [values, setValues] = React.useState({
		textmask: "(100) 000-0000",
		numberformat: "1320",
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

	return (
		<Stack direction="row" spacing={2}>
			<FormControl variant="standard">
				<InputLabel htmlFor="formatted-text-mask-input">
					react-imask
				</InputLabel>
				<Input
					value={values.textmask}
					onChange={handleChange}
					name="textmask"
					id="formatted-text-mask-input"
					inputComponent={PhoneTextMaskCustom as any}
				/>
			</FormControl>
			<TextField
				label="react-number-format"
				value={values.numberformat}
				onChange={handleChange}
				name="numberformat"
				id="formatted-numberformat-input"
				slotProps={{
					input: {
						inputComponent: NumericFormatCustom as any,
					},
				}}
				variant="standard"
			/>
		</Stack>
	);
}
