import axios from "axios";
import Endpoints from "../../Endpoints";
import { IModelFormInput } from "../../views/private/Models/ModelsForm.form";

export interface IModelCalcValues extends Omit<IModelFormInput, "version"> {}

async function calcSpecificValues(data: IModelCalcValues) {
	try {
		const payload = {
			data: {
				montadora: data.manufacturerId,
				procedencia: data.origin,
				cilindradaLitros: data.engineCapacity,
				disposicaoCilindros: data.cilindersDistribuition,
				numeroCilindros: data.cilinderAmount,
				valvulasPorCilindro: data.valvesPerCilinder,
				combustivel: data.fuel,
				alimentacao: data.feeding,
				transmissao: data.transmission,
				tipoCarroceria: data.bodyType,
				categoriaCarroceria: data.bodyCategory,
				luxo: data.premium,
				precoSugerido: data.fipePrice,
			},
		};

		const adjustmentFactor = await axios.post(
			Endpoints.predictions.predict_adjustment_factor as string,
			payload
		);

		const manufacturerPrice = await axios.post(
			Endpoints.predictions.predict_dealership_price as string,
			payload
		);

		const values = {
			adjustmentFactor: adjustmentFactor.data.predictions[0],
			manufacturerPrice: manufacturerPrice.data.predictions[0],
		};
		return values;
	} catch (error) {
		console.error(error);
	}
}

export { calcSpecificValues };
