import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField/TextField";
import { useCallback } from "react";
import { PhoneTextMask } from "../../../components/Masks/PhoneTextMask";
import { createUser } from "../../../services/api/users";
import { sanitizeMaskedString } from "../../../utils/helpers/parsers";

interface IUserSignUpProps {
	companyTaxId: string;
	nextStepHandler: any;
	succefullyCreatedCallback: any;
}

function FormFields(props: IUserSignUpProps) {
	const handleSignUp = useCallback(
		async (event: any) => {
			event.preventDefault();
			const { name, surname, email, password, phone } =
				event.target.elements;
			const user = {
				name: name.value,
				surname: surname.value,
				email: email.value,
				password: password.value,
				phone: sanitizeMaskedString(phone.value),
				company: props.companyTaxId,
				isAdmin: true,
			};
			try {
				await createUser(user);
				props.nextStepHandler();
			} catch (error: any) {
				props.succefullyCreatedCallback(false);
				console.error(error);
			} finally {
				props.nextStepHandler();
			}
		},
		[props]
	);

	return (
		<form onSubmit={handleSignUp}>
			<Grid container spacing={3}>
				<Grid size={{ xs: 12, sm: 12 }}>
					<TextField
						name="name"
						required
						variant="standard"
						fullWidth
						id="name"
						label="Nome"
						autoFocus
					/>
				</Grid>
				<Grid size={{ xs: 12, sm: 12 }}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="surname"
						label="Sobrenome"
						name="surname"
					/>
				</Grid>
				<Grid size={{ xs: 12, sm: 12 }}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="phone"
						label="Celular"
						name="phone"
						slotProps={{
							input: {
								inputComponent: PhoneTextMask as any,
							},
						}}
					/>
				</Grid>
				<Grid size={{ xs: 12, sm: 12 }}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="email"
						label="Email"
						name="email"
					/>
				</Grid>
				<Grid size={{ xs: 12, sm: 12 }}>
					<TextField
						required
						variant="standard"
						color="primary"
						fullWidth
						name="password"
						label="Senha"
						type="password"
						helperText="Mínimo de 6 caracteres"
						id="password"
					/>
				</Grid>
				<Grid size={{ xs: 12, sm: 12 }}>
					<TextField
						required
						variant="standard"
						color="primary"
						fullWidth
						name="passwordConfirmation"
						label="Confirmar Senha"
						type="password"
						id="password_confirmation"
					/>
				</Grid>
			</Grid>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				style={{ marginTop: "16px" }}
			>
				CADASTRAR
			</Button>
		</form>
	);
}

function UserSignUpForm(props: IUserSignUpProps) {
	return (
		<div>
			<Grid size={{ xs: 12 }}>
				<FormFields
					succefullyCreatedCallback={props.succefullyCreatedCallback}
					nextStepHandler={props.nextStepHandler}
					companyTaxId={props.companyTaxId}
				/>
			</Grid>
		</div>
	);
}

export { UserSignUpForm };
