import styled from "styled-components";

const Line = styled.hr`
	width: 100%;
	height: 1px;
	margin: 0px;
	padding: 0px;
	border: 0;
	border-top: 1px solid rgba(255, 2505, 255, 0.7);
`;

export default function BottomLine() {
	return (
		<>
			<Line />
		</>
	);
}
