import { IGroupServiceDto } from "../../../../../../types/Services.types";

export interface IGroupFormInput extends IGroupServiceDto {}

export interface IGroupCreationForm {
	name: string;
}

export const emptyGroupForm: IGroupCreationForm = {
	name: "",
};
