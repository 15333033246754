import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Collections } from "../../Collections";
import { IFipeCodesData } from "../../types/CarModel.types";
import { IFipeModelo } from "../../types/Fipe.types";
import { IOptions } from "../../types/Options.types";
import { db } from "../firestore";

async function getManufacturerById(id: string) {
	try {
		const docSnapshot = await getDoc(
			doc(db, Collections.manufacturers, id.toString())
		);
		return docSnapshot.data() ?? undefined;
	} catch (error) {
		console.error(error);
	}
}

async function addManufacturer(
	manufacturer: IOptions,
	modelsList: IFipeModelo[]
) {
	try {
		const models = modelsList.map((m: IFipeModelo) => {
			return { ...m, fullfilled: false };
		});

		const payload = {
			name: manufacturer.value,
			models: models,
		};
		await setDoc(
			doc(db, Collections.manufacturers, manufacturer.id),
			payload
		);
	} catch (error) {
		console.error(error);
	}
}

async function fullfillModelOnManufacturer(
	manufacturerId: string,
	modelId: string,
	years: number[],
	fipeCodes: IFipeCodesData[]
) {
	try {
		const manufacturer = await getManufacturerById(manufacturerId);

		const newModels = manufacturer!.models.map((m: IFipeModelo) => {
			if (m.Value.toString() === modelId) {
				return {
					...m,
					fullfilled: true,
					years: years,
					fipeCodes: fipeCodes,
				};
			}
			return m;
		});

		const manufacturerRef = doc(
			db,
			Collections.manufacturers,
			manufacturerId.toString()
		);

		await updateDoc(manufacturerRef, {
			models: newModels,
		});
	} catch (error) {
		console.error(error);
	}
}

export { addManufacturer, fullfillModelOnManufacturer, getManufacturerById };
