import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

let firebaseConfig = {};
let openaiKey: string | undefined = "";

if (
	window.location.href.includes("qa") ||
	window.location.href.includes("localhost")
) {
	firebaseConfig = {
		apiKey: process.env.REACT_APP_API_KEY_QA,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN_QA,
		projectId: process.env.REACT_APP_PROJECT_ID_QA,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET_QA,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_QA,
		appId: process.env.REACT_APP_APP_ID_QA,
	};
	openaiKey = process.env.REACT_APP_OPENAI_API_KEY_QA;
} else {
	firebaseConfig = {
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_APP_ID,
	};
	openaiKey = process.env.REACT_APP_OPENAI_API_KEY;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth, firebaseConfig, openaiKey };
