export interface IGroupServiceFormInput {
	system: string;
	service: string;
	percentage: number;
}

export const emptyGroupServiceForm: IGroupServiceFormInput = {
	system: "",
	service: "",
	percentage: 100,
};
