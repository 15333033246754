export interface IAddUserFromInsideForm {
	name: string;
	surname: string;
	phone: string;
	email: string;
	password: string;
	confirmPassword: string;
}

export const AddUserFromInsideEmptyForm: IAddUserFromInsideForm = {
	name: "",
	surname: "",
	phone: "",
	email: "",
	password: "",
	confirmPassword: "",
};
