import { uuidv4 } from "@firebase/util";
import {
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
	writeBatch,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import {
	IServiceCreation,
	IServiceDto,
	ISystemServiceDto,
} from "../../types/Services.types";
import { serviceAttributes } from "../../utils/data/serviceProperties";
import {
	getOptionValueById,
	updateItemInArrayById,
} from "../../utils/helpers/parsers";
import { db } from "../firestore";

async function getServicesFromSystem(systemId: string) {
	try {
		const systemData = await getDoc(doc(db, Collections.systems, systemId));

		const data = systemData.data();

		return data;
	} catch (error) {
		console.error(error);
	}
}

async function getServiceDataById(id: string) {
	try {
		const servcieData = await getDoc(doc(db, Collections.services, id));
		const data = servcieData.data();

		return data;
	} catch (error) {
		console.error(error);
	}
}

async function removeServiceFromSystem(
	service: ISystemServiceDto,
	system: string
) {
	try {
		deleteDoc(doc(db, Collections.services, service.id));

		const servicesInSystem = await getServicesFromSystem(system);

		const serviceInSystem = servicesInSystem?.services.find(
			(s: any) => s.id === service.id
		);

		updateDoc(doc(db, Collections.systems, system), {
			services: arrayRemove(serviceInSystem),
		});
	} catch (error) {
		console.error(error);
	}
}

async function addService(service: IServiceCreation) {
	try {
		const batch = writeBatch(db);
		const id = uuidv4();
		batch.set(doc(db, Collections.services, id), { ...service, id });

		const systemExists = await getServicesFromSystem(service.system);

		if (!systemExists) {
			batch.set(doc(db, Collections.systems, service.system), {
				name: service.system,
				services: [
					{
						description: service.description,
						duration: service.duration,
						id,
					},
				],
			});
		} else {
			batch.update(doc(db, Collections.systems, service.system), {
				name: service.system,
				services: arrayUnion({
					description: service.description,
					duration: service.duration,
					id,
				}),
			});
		}

		await batch.commit();
	} catch (error) {
		console.error(error);
	}
}

async function updateService(serviceId: string, service: IServiceDto) {
	try {
		const system = getOptionValueById(
			service.system,
			serviceAttributes.systemOptions
		);

		const batch = writeBatch(db);

		batch.set(doc(db, Collections.services, serviceId), { ...service });

		const serviceSystem = await getServicesFromSystem(service.system);

		let newServices = serviceSystem!.services;

		newServices = updateItemInArrayById(newServices, serviceId, {
			description: service.description,
			duration: service.duration,
			id: serviceId,
		});

		batch.update(doc(db, Collections.systems, service.system), {
			name: system,
			services: newServices,
		});

		const q = query(
			collection(db, Collections.templates),
			where("serviceIds", "array-contains", serviceId)
		);

		const querySnapshot = await getDocs(q);

		querySnapshot.forEach((d: any) => {
			const data = d.data();

			let newTemplateServices = data.services;

			newTemplateServices = updateItemInArrayById(
				newTemplateServices,
				serviceId,
				service
			);

			batch.update(doc(db, Collections.templates, data.id), {
				services: newTemplateServices,
			});
		});

		await batch.commit();
	} catch (error) {
		console.error(error);
	}
}

export {
	addService,
	getServiceDataById,
	getServicesFromSystem,
	removeServiceFromSystem,
	updateService,
};
