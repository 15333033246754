import TextField from "@mui/material/TextField/TextField";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { addGroupService } from "../../../../../../services/api/models";
import { IFormModalProps } from "../../../../../../types/Modal.types";
import { IGroupServiceDto } from "../../../../../../types/Services.types";
import { IGroupCreationForm, emptyGroupForm } from "./GroupForm.form";

export interface IGroupFormProps extends IFormModalProps {
	group?: IGroupServiceDto;
}

export default function GroupForm(props: IGroupFormProps) {
	const { control, handleSubmit, reset } = useForm<IGroupCreationForm>({
		defaultValues: emptyGroupForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	useEffect(() => {
		if (props.group) {
			reset(props.group);
		}
	}, [props.group, reset]);

	const handleClose = () => {
		reset(emptyGroupForm);
		props.handleCloseModal(false);
	};

	const onSubmit: SubmitHandler<IGroupCreationForm> = async (
		data: IGroupCreationForm
	) => {
		setLoading(true);
		try {
			const payload: IGroupCreationForm = {
				name: data.name,
			};

			await addGroupService(props.id, payload);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Grupo adicionado com sucesso",
				severity: "success",
			});
			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}
			<form id="groupForm" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="name"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="Nome do Grupo"
							type="text"
							fullWidth
							onChange={onChange}
							value={value}
						/>
					)}
				/>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { GroupForm };
