import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid2";
import { Copyright } from "../../components/Copyright";
import SignUp from "./SignUp/SignUp";

export default function LandingPage() {
	return (
		<>
			<Grid
				direction={"row"}
				style={{ minHeight: "100vh" }}
				justifyContent={"space-around"}
				alignItems={"flex-start"}
				spacing={2}
				container
			>
				<Grid
					size={{ sm: 8 }}
					container
					direction={"row"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Box
						component="img"
						sx={{
							padding: "24px",
							marginLeft: "16px",
							borderRadius: "8px",
							width: "80%",
						}}
						alt="MockUps"
						src={require("./../../assets/mock_landing_1.png")}
					/>
				</Grid>

				<Grid style={{ paddingRight: "24px" }} size={{ sm: 4 }}>
					<Box
						sx={{
							border: "1px solid #ffffff",
							padding: "24px",
							paddingTop: "0px",
							marginLeft: "16px",
							borderRadius: "16px",
							marginTop: "16px",
						}}
					>
						<SignUp />
					</Box>
				</Grid>
			</Grid>
			<Copyright />
		</>
	);
}
