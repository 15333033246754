import { useCallback, useContext, useEffect, useState } from "react";
import HeaderLine from "../../../components/Content/HeaderLine";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { AuthContext } from "../../../modules/auth/Auth";

import { DeleteForever, Help } from "@mui/icons-material";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import Fade from "@mui/material/Fade/Fade";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton/IconButton";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import TextField from "@mui/material/TextField/TextField";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import Loading from "../../../components/Loading";
import {
	getCompanyByTaxId,
	updateCompany,
} from "../../../services/api/companies";
import {
	ICompany,
	IPartsPricingRange,
	IUpdateCompanyDTO,
} from "../../../types/Company.types";
import { hasIntersections } from "../../../utils/helpers/checkers";

function PartsPricing() {
	const [ranges, setRanges] = useState<IPartsPricingRange[]>([]);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [partsPricingTax, setPartsPricingTax] = useState<number>(0);
	const [methodCalculus, setMethodCalculus] = useState<string>("simple");
	const [editMode, setEditMode] = useState<boolean>(false);
	const { companyTaxId } = useContext(AuthContext);
	const [companyData, setCompanyData] = useState<ICompany>();
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const changeRangeMinimumValue = (value: number, index: number) => {
		let rangesClone = [...ranges];
		rangesClone[index].minimumValue = value;

		setRanges(rangesClone);
	};

	const changeRangeMaximumValue = (value: number, index: number) => {
		let rangesClone = [...ranges];
		rangesClone[index].maximumValue = value;

		setRanges(rangesClone);
	};

	const changeRangeProfitYield = (value: number, index: number) => {
		let rangesClone = [...ranges];
		rangesClone[index].profitYield = value;

		setRanges(rangesClone);
	};

	function addRangeRow() {
		let rangesClone = [...ranges];
		let newRange: IPartsPricingRange = {
			minimumValue: 0,
			maximumValue: 0,
			profitYield: 0,
		};

		if (!!ranges?.length) {
			newRange.minimumValue =
				ranges![ranges!.length - 1].maximumValue + 0.01;

			newRange.maximumValue =
				ranges![ranges!.length - 1].maximumValue + 0.01;
		}

		rangesClone?.push(newRange);
		setRanges(rangesClone);
	}

	function handleDeleteRangeRow(i: number) {
		try {
			if (ranges.length === 1) {
				throw new Error(
					"É necessário que haja pelo menos uma faixa de precificação"
				);
			}
			let newRanges = [...ranges];
			if (i === 0) {
				newRanges[i + 1].minimumValue = newRanges[i].minimumValue;
			} else {
				newRanges[i - 1].maximumValue = newRanges[i].maximumValue;
			}
			newRanges.splice(i, 1);
			setRanges(newRanges);
		} catch (e: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: e.message as string,
				severity: "error",
			});
		}
	}

	async function handleSave() {
		try {
			setLoading(true);

			const newRangesHaveIntersections = hasIntersections(ranges);

			if (newRangesHaveIntersections) {
				throw new Error(
					"Há valores compreendidos em mais de um intervalo..."
				);
			}

			const doubleCheck = window.confirm(
				"Você tem certeza que deseja salvar as alterações?"
			);

			if (doubleCheck) {
				await updateCompany(companyTaxId!, {
					partsPricing: {
						ranges: ranges,
						tax: partsPricingTax ?? 0,
						calculusMethod: methodCalculus ?? "markup",
					},
				} as IUpdateCompanyDTO);

				setSmartSnackbarConfig({
					open: !smartSnackbarConfig.open,
					message: "Alterações salvas...",
					severity: "success",
				});

				setEditMode(false);
			}
		} catch (error: any) {
			console.error(error);
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
		} finally {
			setLoading(false);
		}
	}

	function handleCancel() {
		const doubleCheck = window.confirm(
			"Você tem certeza que deseja cancelar as alterações?"
		);
		if (doubleCheck) {
			setRefresh(!refresh);
			setEditMode(false);
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Alterações canceladas...",
				severity: "info",
			});
		}
	}

	const getCompanyData = useCallback(async () => {
		try {
			const data = await getCompanyByTaxId(companyTaxId!);
			setCompanyData(data as ICompany);
		} catch (error: any) {
			console.error(error);
		}
	}, [companyTaxId]);

	useEffect(() => {
		getCompanyData();
	}, [getCompanyData, refresh]);

	useEffect(() => {
		setRanges(companyData?.partsPricing?.ranges ?? []);
		setPartsPricingTax(companyData?.partsPricing?.tax ?? 0);
		setMethodCalculus(
			companyData?.partsPricing?.calculusMethod ?? "simple"
		);
	}, [companyData]);

	return (
		<>
			{loading && <Loading />}
			<HeaderLine title="Configurações de precificação" />

			<Grid container spacing={2}>
				<Grid size={{ sm: 6 }}>
					<Grid
						direction={"row"}
						justifyContent={"space-between"}
						container
						marginBottom={1}
					>
						<Grid
							container
							justifyContent={"flex-start"}
							alignItems={"center"}
							size={{ sm: 6 }}
						>
							<Typography align="left" variant="h6">
								Configurações para peças
							</Typography>
						</Grid>

						{editMode ? (
							<ButtonGroup>
								<Button
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => addRangeRow()}
								>
									Adicionar
								</Button>
								<Button
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => handleCancel()}
								>
									Cancelar
								</Button>
								<Button
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => handleSave()}
								>
									Salvar
								</Button>
							</ButtonGroup>
						) : (
							<Button
								variant="outlined"
								color="primary"
								size="small"
								onClick={() => setEditMode(true)}
							>
								Editar
							</Button>
						)}
					</Grid>
					<Box border={1} borderRadius={2} padding={3}>
						<Grid
							container
							justifyContent={"flex-start"}
							alignItems={"center"}
							mb={2}
						>
							<Typography align="left" variant="h6">
								Método de Cálculo{" "}
							</Typography>
							<Tooltip
								placement="right"
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								title={
									<p>
										O método do Cálculo definirá a conta
										feita para chegar no valor de venda.
										Veja os exemplos para uma peça que custa
										R$100,00 com 50%:
										<br />• Simples - R$100,00 + 50% =
										R$150,00
										<br />• Markup - R$100,00 + 50% de
										Markup = R$200,00
									</p>
								}
							>
								<Help
									color="primary"
									style={{ marginLeft: "8px" }}
									fontSize="small"
								/>
							</Tooltip>
						</Grid>
						<Grid container alignItems={"center"} spacing={2}>
							<Grid size={{ xs: 12, sm: 12 }}>
								<Select
									margin="dense"
									id="method-select"
									fullWidth
									style={{ textAlign: "left" }}
									disabled={!editMode}
									required
									onChange={(e: any) =>
										setMethodCalculus(e.target.value)
									}
									value={methodCalculus}
								>
									<MenuItem value={"simple"}>
										Simples
									</MenuItem>
									<MenuItem value={"markup"}>Markup</MenuItem>
								</Select>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent={"flex-start"}
							alignItems={"center"}
							mb={2}
						>
							<Typography align="left" variant="h6">
								Faixas de precificação de peças
							</Typography>
							<Tooltip
								placement="right"
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								title="As faixas de precificação definem qual será o percentual de acréscimo para venda de peças com preço de custo entre o valor mínimo e máximo estabelecido na faixa. 
								No momento do preenchimento do orçamento das peças na Ordem de Serviço, o Sistema utilizará essa configuração para facilitar o seu dia a dia."
							>
								<Help
									color="primary"
									style={{ marginLeft: "8px" }}
									fontSize="small"
								/>
							</Tooltip>
						</Grid>
						{ranges.length ? (
							ranges?.map((r: IPartsPricingRange, i: number) => {
								return (
									<Grid
										container
										alignItems={"center"}
										spacing={2}
									>
										{editMode && (
											<Grid size={{ xs: 1, sm: 1 }}>
												<IconButton
													color="error"
													aria-label="delete"
													size="large"
													onClick={() =>
														handleDeleteRangeRow(i)
													}
												>
													<DeleteForever />
												</IconButton>
											</Grid>
										)}
										<Grid
											size={{
												xs: editMode ? 3 : 4,
												sm: editMode ? 3 : 4,
											}}
										>
											<TextField
												margin="dense"
												id="minimum-value"
												label="Valor mínimo de custo"
												type="number"
												fullWidth
												required
												disabled={!editMode}
												InputProps={{
													inputProps: {
														min: 0,
														step: "0.01",
													},
													startAdornment: {
														...(
															<InputAdornment position="start">
																R$
															</InputAdornment>
														),
													},
												}}
												onChange={(e: any) =>
													changeRangeMinimumValue(
														parseFloat(
															e.target.value
														) as number,
														i
													)
												}
												value={r.minimumValue}
											/>
										</Grid>

										<Grid
											size={{
												xs: editMode ? 3 : 4,
												sm: editMode ? 3 : 4,
											}}
										>
											<TextField
												margin="dense"
												id="maximum-value"
												label="Valor máximo de custo"
												type="number"
												fullWidth
												required
												disabled={!editMode}
												InputProps={{
													inputProps: {
														min: 0,
														step: "0.01",
													},
													startAdornment: {
														...(
															<InputAdornment position="start">
																R$
															</InputAdornment>
														),
													},
												}}
												onChange={(e: any) =>
													changeRangeMaximumValue(
														parseFloat(
															e.target.value
														) as number,
														i
													)
												}
												value={r.maximumValue}
											/>
										</Grid>

										<Grid
											size={{
												xs: editMode ? 3 : 4,
												sm: editMode ? 3 : 4,
											}}
										>
											<TextField
												margin="dense"
												id="profit-yield"
												label="Porcentagem a ser aplicada"
												type="number"
												fullWidth
												disabled={!editMode}
												required
												InputProps={{
													inputProps: {
														min: 0,
														step: "0.01",
													},
													endAdornment: {
														...(
															<InputAdornment position="end">
																%
															</InputAdornment>
														),
													},
												}}
												onChange={(e: any) =>
													changeRangeProfitYield(
														parseFloat(
															e.target.value
														) as number,
														i
													)
												}
												value={r.profitYield}
											/>
										</Grid>
									</Grid>
								);
							})
						) : (
							<Typography variant="h6">
								Adicione uma faixa de precificação
							</Typography>
						)}
						<Grid
							container
							justifyContent={"flex-start"}
							alignItems={"center"}
							mb={2}
						>
							<Typography align="left" variant="h6">
								Alíquota de imposto sobre NF
							</Typography>
							<Tooltip
								placement="right"
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								title="Insira o percentual de imposto que você paga sobre o valor de venda da peça."
							>
								<Help
									color="primary"
									style={{ marginLeft: "8px" }}
									fontSize="small"
								/>
							</Tooltip>
						</Grid>

						<Grid container alignItems={"center"} spacing={2}>
							<Grid size={{ xs: 12, sm: 12 }}>
								<TextField
									margin="dense"
									id="profit-yield"
									label="Alíquota (%)"
									type="number"
									fullWidth
									disabled={!editMode}
									required
									InputProps={{
										inputProps: {
											min: 0,
											step: "0.01",
										},
										endAdornment: {
											...(
												<InputAdornment position="end">
													%
												</InputAdornment>
											),
										},
									}}
									onChange={(e: any) =>
										setPartsPricingTax(
											parseFloat(e.target.value) as number
										)
									}
									value={partsPricingTax}
								/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}

export { PartsPricing };
