import { History } from "@mui/icons-material";
import Restore from "@mui/icons-material/Restore";
import Unpublished from "@mui/icons-material/Unpublished";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import Breadcrumbs from "@mui/material/Breadcrumbs/Breadcrumbs";
import Button from "@mui/material/Button/Button";
import Chip from "@mui/material/Chip/Chip";
import Drawer from "@mui/material/Drawer/Drawer";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab/Tab";
import Typography from "@mui/material/Typography/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../../components/Loading";
import {
	DiscreteHeader,
	Header,
} from "../../../../components/Typography/Typography";
import { AuthContext } from "../../../../modules/auth/Auth";
import {
	finishOrder,
	getOrder,
	reactivateOrder,
} from "../../../../services/api/orders";
import { IChangeLogBase } from "../../../../types/ChangeLog.types";
import { IOrderDto } from "../../../../types/Order.types";
import OrderHistory from "./Tabs/History/OrderHistory";
import OrderPartsDetails from "./Tabs/Parts/OrderPartsDetails";

export default function OrderDetails() {
	let { id } = useParams<{ id: string }>();
	const [order, setOrder] = useState<IOrderDto>();
	const [refresh, setRefresh] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>();
	const [loading, setLoading] = useState<boolean>(false);
	const [value, setValue] = useState("2");
	const [partsSum, setPartsSum] = useState<number>(0);
	const [openHistory, setOpenHistory] = useState<boolean>(false);
	const { name, surname, email } = useContext(AuthContext);

	const handleChange = (_event: any, newValue: string) => {
		setValue(newValue);
	};

	const orderData = useCallback(async () => {
		try {
			setLoading(true);
			const data = await getOrder(id!);
			setOrder(data as IOrderDto);
		} catch (error: any) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		orderData();
		setIsActive(order?.isActive);
	}, [order?.isActive, orderData, refresh]);

	const handleOrderStateChange = async () => {
		try {
			const changeLog: IChangeLogBase = {
				targetIdentifier: order?.sequentialId!,
				author: {
					email: email!,
					name: name!,
					surname: surname!,
				},
				scope: "order",
				type: isActive ? "finish" : "reopen",
				date: new Date(),
			};
			isActive
				? await finishOrder(id!, changeLog)
				: await reactivateOrder(id!, changeLog);
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{loading && <Loading />}
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
				spacing={1}
			>
				<Grid size={{ xs: 8 }}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link
							style={{ textDecorationLine: "none" }}
							color="inherit"
							to="/orders"
						>
							<Typography
								style={{
									color: "#ffffff",
								}}
							>
								Orçamentos
							</Typography>{" "}
						</Link>
						<Typography color="primary">
							{order?.manufacturer}
						</Typography>
						<Typography color="primary">{order?.model}</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>

			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<Grid>
					<DiscreteHeader
						text={`Criado em ${order?.createdDate!.toLocaleDateString(
							"pt-BR",
							{
								timeZone: "America/Sao_Paulo",
							}
						)}`}
					/>
				</Grid>

				<Grid container justifyContent="flex-end" size={{ xs: 2 }}>
					<Button
						size="small"
						variant="contained"
						endIcon={isActive ? <Unpublished /> : <Restore />}
						style={{
							backgroundColor: isActive ? "#f44336" : "#44A274",
							color: "white",
						}}
						onClick={() => handleOrderStateChange()}
					>
						{isActive ? "Marcar como Finalizada" : "Reativar"}
					</Button>
				</Grid>
			</Grid>

			<br />

			<Grid
				justifyContent="space-between"
				alignItems="flex-end"
				container
			>
				<Header text={`Placa: ${order?.plate.toUpperCase() ?? ""}`} />
			</Grid>
			<Grid
				justifyContent="space-between"
				alignItems="flex-end"
				container
			>
				<Grid size={{ sm: 12, md: 4 }}>
					<Header
						text={`Modelo: ${order?.manufacturer.toUpperCase()} - ${order?.model.toUpperCase()} - ${order?.year.toUpperCase()}`}
					/>
				</Grid>

				<Grid
					size={{ sm: 12, md: 4 }}
					justifyContent="center"
					alignItems="flex-end"
					container
					spacing={1}
				>
					<Grid>
						<Chip
							size="small"
							color="info"
							label={`Total Peças: R$ ${partsSum
								.toFixed(2)
								.toString()
								.replace(".", ",")}`}
							style={{ fontWeight: "bold", fontSize: "1rem" }}
						/>
					</Grid>
				</Grid>

				<Grid
					size={{ sm: 12, md: 4 }}
					justifyContent="flex-end"
					alignItems="flex-end"
					container
					spacing={1}
				>
					<Button
						endIcon={<History />}
						variant="contained"
						color="primary"
						size="small"
						onClick={() => setOpenHistory(true)}
					>
						Histórico
					</Button>
					<Drawer
						anchor={"right"}
						open={openHistory}
						onClose={() => setOpenHistory(false)}
					>
						<OrderHistory />
					</Drawer>
				</Grid>
			</Grid>

			<TabContext value={value}>
				<TabList
					// style={{
					// 	color: "#ff7221",
					// 	minHeight: 60,
					// 	paddingTop: 0,
					// 	paddingBottom: 0,
					// }}
					onChange={handleChange}
					indicatorColor="primary"
				>
					{/* <Tab label="SERVIÇOS" value="1" /> */}
					<Tab
						// style={{
						// 	color: "#ff7221",
						// 	minHeight: 60,
						// 	paddingBottom: 0,
						// }}
						// icon={<SettingsOutlined fontSize="small" />}
						iconPosition="start"
						label="PEÇAS"
						value="2"
					/>
					{/* <Tab label="RESUMO" value="3" /> */}
				</TabList>
				{/* <TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="1"
				>
					<OrderServiceDetails
						refreshParent={refresh}
						refreshParentHandler={setRefresh}
					/>
				</TabPanel> */}
				<TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="2"
				>
					<OrderPartsDetails
						modelDescription={""}
						modelPlate={order?.plate.toUpperCase() ?? ""}
						refreshParentHandler={setPartsSum}
					/>
				</TabPanel>
			</TabContext>
		</>
	);
}
