import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import CardActions from "@mui/material/CardActions/CardActions";
import CardContent from "@mui/material/CardContent/CardContent";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography/Typography";
import { IUser } from "../../types/User.types";

interface IUserCardProps {
	user: IUser;
}

export default function UserCard(props: IUserCardProps) {
	return (
		<>
			<Grid
				size={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				style={{
					display: "flex",
					flexDirection: "column",
					marginBottom: "8px",
				}}
			>
				<Card
					sx={{
						minWidth: 275,
						flexDirection: "column",
						textAlign: "left",
					}}
				>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							{props.user.name}
						</Typography>
						<Typography variant="h6" gutterBottom>
							{props.user.company}
						</Typography>
						<Typography variant="subtitle1" gutterBottom>
							{props.user.phone}
						</Typography>
						<Typography variant="subtitle2" gutterBottom>
							{props.user.email}
						</Typography>
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							color="primary"
							size="small"
						>
							Detalhes
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</>
	);
}
