import { uuidv4 } from "@firebase/util";
import { Add, Cancel, Delete, Save } from "@mui/icons-material";
import Button from "@mui/material/Button/Button";
import { GridActionsCellItem } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import { DataGrid, GridSlots } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridRowModesModel } from "@mui/x-data-grid/models/api/gridEditingApi";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridEventListener } from "@mui/x-data-grid/models/events/gridEventListener";
import { GridRowModes } from "@mui/x-data-grid/models/gridEditRowModel";
import {
	GridRowId,
	GridRowModel,
	GridRowsProp,
} from "@mui/x-data-grid/models/gridRows";
import { GridRowEditStopReasons } from "@mui/x-data-grid/models/params/gridRowParams";
import { Timestamp } from "firebase/firestore";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { GRID_DEFAULT_LOCALE_TEXT } from "../../hooks/Contexts/gridLocale";
import { AuthContext } from "../../modules/auth/Auth";
import {
	deletePartEstimate,
	getOrder,
	updatePartsEstimates,
} from "../../services/api/orders";
import { IChangeLogBase } from "../../types/ChangeLog.types";
import { IPartsPricing } from "../../types/Company.types";
import { IOrderPartsDto, IPartEstimateDto } from "../../types/Parts.types";
import { getProfitYieldForValue } from "../../utils/helpers/checkers";
import {
	markupPartCalculus,
	simplePartCalculus,
} from "../../utils/helpers/processors";
import Loading from "../Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../Snackbars/SmartSnackbar";

export interface IEditableOrderPartsProps {
	orderId: string;
	part: IOrderPartsDto;
	refreshParent: boolean;
	refreshParendHandler: any;
	fullView: boolean;
	partsPricing: IPartsPricing;
	partName: string;
}

interface EditToolbarProps {
	setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
	setRowModesModel: (
		newModel: (oldModel: GridRowModesModel) => GridRowModesModel
	) => void;
}

const StyledDataGrid = styled(DataGrid)(() => ({
	"& .super-app-theme--BestOption": {
		backgroundColor: "#238027",
		"&:hover": {
			backgroundColor: "#43a047",
		},
	},
}));

function EditToolbar(props: EditToolbarProps) {
	const { setRows, setRowModesModel } = props;

	const handleClick = () => {
		const id = uuidv4();
		setRows((oldRows) => [
			...oldRows,
			{
				id,
				provider: "",
				costPrice: 0,
				amount: 0,
				sellUnitPrice: 0,
				sellPrice: 0,
				brand: "",
				isBestOption: false,
			},
		]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
		}));
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={<Add />} onClick={handleClick}>
				Cotação
			</Button>
		</GridToolbarContainer>
	);
}

export default function EditableOrderParts(props: IEditableOrderPartsProps) {
	const [rows, setRows] = useState<any>([]);
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{}
	);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});
	const [refetchEstimates, setRefetchEstimates] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentBestOption, setCurrentBestOption] =
		useState<IPartEstimateDto>();
	const { email, name, surname, partCalculusMethod } =
		useContext(AuthContext);

	const getOrderParts = useCallback(async () => {
		try {
			const data = await getOrder(props.orderId);

			const desiredPart = data?.parts.find(
				(p: IOrderPartsDto) => p.id === props.part.id
			);
			setRows(desiredPart?.estimates ?? []);
			const bestOption = desiredPart?.estimates.find(
				(e: IPartEstimateDto) => e.isBestOption === true
			);

			if (!!bestOption?.id) {
				setCurrentBestOption(bestOption);
			} else {
				setCurrentBestOption(undefined);
			}
		} catch (error) {
			console.error(error);
		}
	}, [props.orderId, props.part.id]);

	useEffect(() => {
		getOrderParts();
	}, [getOrderParts, refetchEstimates, props.part]);

	const handleRowEditStop: GridEventListener<"rowEditStop"> = (
		params,
		event
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleDeleteClick = (id: string) => async () => {
		try {
			setLoading(true);
			const changeLog: IChangeLogBase = {
				targetIdentifier: ``,
				author: {
					email: email!,
					name: name!,
					surname: surname!,
				},
				scope: "partEstimate",
				type: "remove",
				date: new Date(),
			};
			await deletePartEstimate(
				props.orderId,
				props.part.id,
				id.toString(),
				changeLog
			);
			setRefetchEstimates(!refetchEstimates);
		} catch (error) {
			console.error(error);
		} finally {
			props.refreshParendHandler(!props.refreshParent);
			setLoading(false);
		}
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row: any) => row.id === id);
		if (editedRow!.isNew) {
			setRows(rows.filter((row: any) => row.id !== id));
		}
	};

	const processRowUpdate = async (
		newRow: GridRowModel,
		oldRow: GridRowModel
	) => {
		const profitYieldForValue = getProfitYieldForValue(
			props.partsPricing.ranges,
			newRow.costPrice
		);

		const newEstimate: IPartEstimateDto = {
			provider: newRow.provider,
			id: newRow.id,
			term: newRow.term,
			costPrice: newRow.costPrice,
			sellUnitPrice:
				partCalculusMethod === "simple"
					? simplePartCalculus(
							newRow.costPrice,
							(profitYieldForValue ?? 0) / 100
					  )
					: markupPartCalculus(
							newRow.costPrice,
							(profitYieldForValue ?? 0) / 100
					  ),
			amount: newRow.amount,
			brand: newRow.brand,
			isBestOption: newRow.isBestOption,
		};

		const changeLog: IChangeLogBase = {
			targetIdentifier: `${props.partName} do fornecedor ${newRow.provider} da marca ${newRow.brand}`,
			author: {
				email: email!,
				name: name!,
				surname: surname!,
			},
			scope: "partEstimate",
			type:
				oldRow.brand === "" && oldRow.provider === ""
					? "add"
					: "update",
			date: new Date(),
		};

		try {
			setLoading(true);
			if (!profitYieldForValue) {
				throw new Error(
					"O valor de custo não possui uma configuração de margem de lucro... Configure antes de continuar."
				);
			}
			if (
				!!currentBestOption &&
				newEstimate.isBestOption &&
				newEstimate.id !== currentBestOption?.id
			) {
				await updatePartsEstimates(
					props.orderId,
					props.part.id,
					newEstimate,
					changeLog,
					currentBestOption.id
				);
			} else {
				await updatePartsEstimates(
					props.orderId,
					props.part.id,
					newEstimate!,
					changeLog
				);
			}
		} catch (error: any) {
			console.error(error);
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
		} finally {
			setLoading(false);
			setRefetchEstimates(!refetchEstimates);
			props.refreshParendHandler(!props.refreshParent);
		}

		const updatedRow = { ...newRow, isNew: false };
		setRows(
			rows.map((row: any) => (row.id === newRow.id ? updatedRow : row))
		);
		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const columns: GridColDef[] = [
		{
			field: "provider",
			headerName: "Fornecedor",
			minWidth: 90,
			flex: 1,
			editable: true,
		},
		{
			field: "term",
			headerName: "Prazo",
			minWidth: 110,
			flex: 0.5,
			editable: true,
		},
		{
			field: "brand",
			headerName: "Marca",
			minWidth: 110,
			flex: 0.5,
			editable: true,
		},
		{
			field: "amount",
			headerName: "Qtde.",
			type: "number",
			minWidth: 80,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			editable: true,
		},
		{
			field: "costPrice",
			headerName: "Custo Un.",
			type: "number",
			minWidth: 110,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			editable: true,
			valueFormatter: (value: number) =>
				`R$${(value ?? 0).toFixed(2).toString().replace(".", ",")}`,
		},
		{
			field: "totalCostPrice",
			headerName: "Custo Total",
			type: "number",
			minWidth: 110,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			editable: false,
			valueFormatter: (_value: number, row: any) =>
				`R$${(row.costPrice * row.amount)
					.toFixed(2)
					.toString()
					.replace(".", ",")}`,
		},
		{
			field: "sellUnitPrice",
			headerName: "Venda Un.",
			type: "number",
			minWidth: 110,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			editable: false,
			valueFormatter: (value: number) =>
				`R$${value.toFixed(2).toString().replace(".", ",")}`,
		},
		{
			field: "totalSellPrice",
			headerName: "Venda Total",
			type: "number",
			minWidth: 110,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			editable: false,
			valueFormatter: (_value: number, row: any) =>
				`R$${(row.sellUnitPrice * row.amount)
					.toFixed(2)
					.toString()
					.replace(".", ",")}`,
		},
		{
			field: "isBestOption",
			headerName: "Vencedor",
			type: "boolean",
			minWidth: 110,
			flex: 0.5,
			editable: true,
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			getActions: ({ id }) => {
				const isInEditMode =
					rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<Save />}
							label="Save"
							sx={{
								color: "primary.main",
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<Cancel />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<Delete />}
						label="Delete"
						onClick={handleDeleteClick(id as string)}
						color="inherit"
					/>,
				];
			},
		},
	];

	const customerColumns: GridColDef[] = [
		{
			field: "brand",
			headerName: "Marca",
			minWidth: 110,
			flex: 0.5,
		},
		{
			field: "term",
			headerName: "Prazo",
			minWidth: 110,
			flex: 0.5,
			editable: true,
		},
		{
			field: "amount",
			headerName: "Qtde.",
			type: "number",
			minWidth: 80,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "sellPrice",
			headerName: "Venda Un.",
			type: "number",
			minWidth: 110,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			valueFormatter: (_value: number, row: any) =>
				`R$${(partCalculusMethod === "simple"
					? simplePartCalculus(row.costPrice, row.profitFactor / 100)
					: markupPartCalculus(row.costPrice, row.profitFactor / 100)
				)
					.toFixed(2)
					.toString()
					.replace(".", ",")}`,
		},
		{
			field: "totalSellPrice",
			headerName: "Venda Total",
			type: "number",
			minWidth: 110,
			flex: 0.5,
			align: "center",
			headerAlign: "center",
			valueFormatter: (_value: number, row: any) =>
				`R$${(
					(partCalculusMethod === "simple"
						? simplePartCalculus(
								row.costPrice,
								row.profitFactor / 100
						  )
						: markupPartCalculus(
								row.costPrice,
								row.profitFactor / 100
						  )) * row.amount
				)
					.toFixed(2)
					.toString()
					.replace(".", ",")}`,
		},
		{
			field: "date",
			headerName: "Data",
			headerAlign: "center",
			align: "center",
			type: "date",
			minWidth: 110,
			flex: 0.5,
			valueGetter: (value: Timestamp) => value.toDate(),
		},
	];

	return (
		<>
			{loading && <Loading />}
			<StyledDataGrid
				rows={rows}
				columns={props.fullView ? columns : customerColumns}
				editMode="row"
				rowModesModel={rowModesModel}
				density="compact"
				onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				disableColumnResize
				localeText={GRID_DEFAULT_LOCALE_TEXT}
				disableRowSelectionOnClick
				processRowUpdate={processRowUpdate}
				getRowClassName={(params) =>
					`super-app-theme--${
						params.row.isBestOption ? "BestOption" : "Default"
					}`
				}
				initialState={{
					columns: {
						columnVisibilityModel: {
							provider: props.fullView,
							costPrice: props.fullView,
							totalCostPrice: props.fullView,
							profictFactor: props.fullView,
						},
					},
					sorting: {
						sortModel: [{ field: "date", sort: "desc" }],
					},
				}}
				slots={{
					toolbar: EditToolbar as GridSlots["toolbar"],
				}}
				slotProps={{
					toolbar: { setRows, setRowModesModel },
				}}
			/>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}
