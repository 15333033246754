export interface ICreateCompanyForm {
	name: string;
	taxId: string;
	phone: string;
	email: string;
	address_street: string;
	address_number: string;
	address_complement: string;
	address_neighborhood: string;
	address_city: string;
	address_state: string;
	address_zipCode: string;
}

export const createCompanyEmptyForm: ICreateCompanyForm = {
	name: "",
	taxId: "",
	phone: "",
	email: "",
	address_street: "",
	address_number: "",
	address_complement: "",
	address_neighborhood: "",
	address_city: "",
	address_state: "",
	address_zipCode: "",
};
