import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AppRoutes } from "./AppRoutes";
import { theme } from "./hooks/Contexts/theme";
import { AuthProvider } from "./modules/auth/Auth";

function App() {
	return (
		<div className="App">
			{/* Inject Emotion before JSS */}
			{/* <StyledEngineProvider injectFirst> */}
			<BrowserRouter>
				<AuthProvider>
					<ThemeProvider theme={theme}>
						<AppRoutes />
					</ThemeProvider>
				</AuthProvider>
			</BrowserRouter>
			{/* </StyledEngineProvider> */}
		</div>
	);
}

export default App;
