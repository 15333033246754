import {
	arrayRemove,
	arrayUnion,
	deleteDoc,
	doc,
	getDoc,
	updateDoc,
	writeBatch,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import { IServiceTemplate } from "../../types/ServiceTemplates.types";
import { updateItemInArrayById } from "../../utils/helpers/parsers";
import { db } from "../firestore";

async function getTemplateById(id: string) {
	try {
		const templateData = await getDoc(doc(db, Collections.templates, id));

		const data = templateData.data();

		return data;
	} catch (error) {
		console.error(error);
	}
}

async function addServiceTemplate(template: IServiceTemplate) {
	try {
		const batch = writeBatch(db);

		batch.set(doc(db, Collections.templates, template.id), template);

		const allTemplatesExists = await getTemplateById("all_templates");

		if (!allTemplatesExists) {
			batch.set(doc(db, Collections.templates, "all_templates"), {
				templates: [
					{
						description: template.description,
						id: template.id,
					},
				],
			});
		} else {
			batch.update(doc(db, Collections.templates, "all_templates"), {
				templates: arrayUnion({
					description: template.description,
					id: template.id,
				}),
			});
		}

		await batch.commit();
	} catch (error) {
		console.error(error);
	}
}

async function deleteTemplateById(id: string) {
	try {
		deleteDoc(doc(db, Collections.templates, id));

		const templates = await getTemplateById("all_templates");

		const template = templates?.templates.find((t: any) => t.id === id);

		await updateDoc(doc(db, Collections.templates, "all_templates"), {
			templates: arrayRemove(template),
		});
	} catch (error) {
		console.error(error);
	}
}

async function updateServiceTemplate(data: IServiceTemplate) {
	try {
		await updateDoc(doc(db, Collections.templates, data.id), {
			updatedDate: data.updatedDate,
			services: data.services,
			serviceIds: data.serviceIds,
			description: data.description,
		});

		const oldAllTemplatesData = await getTemplateById("all_templates");

		const newTemplates = updateItemInArrayById(
			oldAllTemplatesData!.templates,
			data.id,
			{ description: data.description, id: data.id }
		);

		await updateDoc(doc(db, Collections.templates, "all_templates"), {
			templates: newTemplates,
		});
	} catch (error) {
		console.error(error);
	}
}

export {
	addServiceTemplate,
	deleteTemplateById,
	getTemplateById,
	updateServiceTemplate,
};
