export interface IServiceTemplateServiceFormInput {
	system: string;
	service: string;
}

export const emptyServiceTemplateServiceForm: IServiceTemplateServiceFormInput =
	{
		system: "",
		service: "",
	};
