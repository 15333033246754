import { Delete, Visibility } from "@mui/icons-material";
import Button from "@mui/material/Button/Button";

export interface IActionButtonsProps {
	onDelete?: any;
	onEdit?: any;
}

function ActionButtons(props: IActionButtonsProps) {
	return (
		<>
			<div>
				{props.onEdit && (
					<Button
						size="small"
						variant="contained"
						color="primary"
						endIcon={<Visibility />}
						onClick={() => props.onEdit()}
					>
						Visualizar{" "}
					</Button>
				)}
				{props.onDelete && (
					<Button
						style={{ backgroundColor: "#a3081a", color: "white" }}
						variant="contained"
						endIcon={<Delete />}
						onClick={() => props.onDelete()}
						size="small"
					>
						Apagar{" "}
					</Button>
				)}
				{/* <IconButton
					color='primary'
					aria-label='delete'
					className={classes.margin}
				>
					<Edit />
				</IconButton>
				<IconButton
					color='default'
					aria-label='delete'
					className={classes.margin}
				>
					<Delete />
				</IconButton> */}
			</div>
		</>
	);
}

export { ActionButtons };
