import { IPartsPricingRange } from "../../types/Company.types";

// Função para verificar se dois ranges se intersectam
function isIntersection(
	range1: IPartsPricingRange,
	range2: IPartsPricingRange
): boolean {
	return (
		range1.maximumValue >= range2.minimumValue &&
		range2.maximumValue >= range1.minimumValue
	);
}

// Função para verificar se há interseções na lista de ranges
function hasIntersections(ranges: IPartsPricingRange[]): boolean {
	for (let i = 0; i < ranges.length; i++) {
		for (let j = i + 1; j < ranges.length; j++) {
			if (isIntersection(ranges[i], ranges[j])) {
				return true;
			}
		}
	}
	return false;
}

// Função para verificar se o intervalo entre 0.01 e 100000 está coberto
function isRangeCovered(
	ranges: IPartsPricingRange[],
	min: number,
	max: number
): boolean {
	let currentMin = min;

	// Ordena os ranges pelo valor mínimo
	ranges.sort((a, b) => a.minimumValue - b.minimumValue);

	for (const range of ranges) {
		if (range.minimumValue > currentMin) {
			// Se houver um gap, o intervalo não está completamente coberto
			return false;
		}
		currentMin = Math.max(currentMin, range.maximumValue);
	}
	return currentMin >= max;
}

// Função que retorna o profitYield correspondente ao valor fornecido
function getProfitYieldForValue(
	ranges: IPartsPricingRange[],
	value: number
): number | null {
	for (const range of ranges) {
		if (value >= range.minimumValue && value <= range.maximumValue) {
			return range.profitYield;
		}
	}
	return null; // Retorna null se o valor não estiver em nenhuma faixa
}

export {
	getProfitYieldForValue,
	hasIntersections,
	isIntersection,
	isRangeCovered,
};
