import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Collections } from "../../Collections";
import { IChangeLog, IChangeLogDto } from "../../types/ChangeLog.types";
import { db } from "../firestore";

async function getChangeLogsFromOrder(orderId: string) {
	const docSnapshot = await getDoc(doc(db, Collections.changeLogs, orderId));
	const data = docSnapshot.data();

	if (!!data) {
		return data as IChangeLogDto;
	}

	return undefined;
}

async function addChangeLogToOrder(changeLog: IChangeLog, orderId: string) {
	try {
		const existentChangeLogs = await getChangeLogsFromOrder(orderId);

		if (!!existentChangeLogs) {
			let newChanges = [changeLog, ...existentChangeLogs.changeLogs];

			await updateDoc(doc(db, Collections.changeLogs, orderId), {
				...existentChangeLogs,
				changeLogs: newChanges,
			});
		} else {
			await setDoc(doc(db, Collections.changeLogs, orderId), {
				orderId: orderId,
				changeLogs: [changeLog],
			} as IChangeLogDto);
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export { addChangeLogToOrder, getChangeLogsFromOrder };
