import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

export default function Loading() {
	return (
		<div>
			<Backdrop
				style={{
					zIndex: 5000,
				}}
				open={true}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
