import HighlightOff from "@mui/icons-material/Highlight";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import Button from "@mui/material/Button/Button";
import Chip from "@mui/material/Chip/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton/IconButton";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TextField from "@mui/material/TextField/TextField";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../components/Loading";
import FormModal from "../../../../../components/Modals/FormModal";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../components/Snackbars/SmartSnackbar";
import { Header } from "../../../../../components/Typography/Typography";
import { getServiceDataById } from "../../../../../services/api/services";
import {
	getTemplateById,
	updateServiceTemplate,
} from "../../../../../services/api/templates";
import { IServiceTemplate } from "../../../../../types/ServiceTemplates.types";
import { IServiceDto } from "../../../../../types/Services.types";
import { serviceAttributes } from "../../../../../utils/data/serviceProperties";
import {
	getOptionValueById,
	parseDecimalToHoursAndMinutes,
} from "../../../../../utils/helpers/parsers";
import {
	IServiceTemplateForm,
	emptyTemplateForm,
} from "./ServiceTemplateForm.form";
import ServiceTemplateServiceForm from "./ServiceTemplateServiceForm";

interface IChangeLog {
	type: "percentage" | "addition" | "removal";
	serviceId: string;
}

interface GroupedService {
	system: string;
	services: IServiceDto[];
}

export interface IGroupDetailsProps {
	templateId: string;
	refreshParentHandler: any;
	refreshParent: boolean;
	closeModal: any;
}

function ServiceTemplateDetails(props: IGroupDetailsProps) {
	const [template, setTemplate] = useState<IServiceTemplate>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [services, setServices] = useState<IServiceDto[]>([]);
	const [changeLog, setChangeLog] = useState<IChangeLog[]>([]);
	const [pendencies, setPendencies] = useState<number>(0);
	const { control, handleSubmit, reset } = useForm<IServiceTemplateForm>({
		defaultValues: emptyTemplateForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const getData = useCallback(async () => {
		try {
			const template = await getTemplateById(props.templateId);
			setTemplate(template as IServiceTemplate);
			reset(template);

			setServices(template!.services);
		} catch (error) {
			console.error(error);
		}
	}, [props.templateId, reset]);

	useEffect(() => {
		getData();
	}, [getData]);

	function addChange(
		serviceId: any,
		type: "percentage" | "addition" | "removal"
	) {
		let changeLogNewArr = changeLog;

		const searchIndex = changeLog.findIndex(
			(c) => c.serviceId === serviceId
		);

		if (searchIndex === -1) {
			changeLogNewArr.push({
				type,
				serviceId,
			});

			setChangeLog(changeLogNewArr);
			setPendencies(changeLogNewArr.length);
		}
	}

	useEffect(() => {
		// Cria um mapa para agrupar os serviços por sistema
		const groupedServicesMap: { [key: string]: IServiceDto[] } = {};

		// Itera sobre a lista de serviços e agrupa por sistema
		services.forEach((service) => {
			if (!groupedServicesMap[service.system]) {
				groupedServicesMap[service.system] = [];
			}
			groupedServicesMap[service.system].push(service);
		});

		// Converte o mapa para uma lista de objetos GroupedService
		const groupedServicesList: GroupedService[] = Object.keys(
			groupedServicesMap
		).map((system) => ({
			system,
			services: groupedServicesMap[system],
		}));

		setData(groupedServicesList);
	}, [services]);

	async function addServiceHandler(e: any) {
		const serviceToAdd = await getServiceDataById(e);

		const serviceAlreadyExists = services.find(
			(x: IServiceDto) => x.id === e
		);

		if (!serviceAlreadyExists) {
			setServices([...services, serviceToAdd as IServiceDto]);
			addChange(e.id, "addition");
		} else {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Este serviço já existe no grupo",
				severity: "error",
			});
		}
	}

	const onSubmit: SubmitHandler<IServiceTemplateForm> = async (
		formData: IServiceTemplateForm
	) => {
		setLoading(true);
		try {
			const payload: IServiceTemplate = {
				createdBy: "",
				description: formData.description,
				services: services,
				serviceIds: services.map((s: IServiceDto) => s.id),
				id: template!.id,
				updatedDate: new Date(),
				createdDate: template!.createdDate,
			};

			await updateServiceTemplate(payload);
			setChangeLog([]);
			setPendencies(0);
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Alterações salvas",
				severity: "success",
			});
			props.refreshParentHandler(!props.refreshParent);
			props.closeModal();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Este serviço já existe no grupo",
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	async function removeService(serviceId: string) {
		const newServices = services.filter(
			(s: IServiceDto) => s.id !== serviceId
		);
		setServices(newServices);
		addChange(serviceId, "removal");
	}

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	return (
		<>
			{loading && <Loading />}

			<Grid
				container
				direction="row"
				spacing={2}
				justifyContent="space-between"
				style={{ marginBottom: "16px" }}
			>
				<Grid
					size={{ xs: 12, sm: 12, md: 9, lg: 10 }}
					direction="column"
					justifyContent="flex-end"
				>
					<Chip
						style={{
							backgroundColor: `#${
								pendencies > 0 ? "212121" : "ff7221"
							}`,
							color: "white",
						}}
						label={
							pendencies > 0
								? "Há alterações não salvas"
								: "Não há alterações"
						}
					/>

					<form
						id="templateDetailsForm"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Controller
							name="description"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									autoFocus
									margin="dense"
									id="description"
									label="Nome do Template"
									type="text"
									fullWidth
									style={{ marginBottom: "0px" }}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					</form>
				</Grid>

				<Grid
					size={{ xs: 12, sm: 12, md: 9, lg: 10 }}
					direction="column"
					justifyContent="flex-end"
				>
					<Button
						variant="contained"
						color="primary"
						endIcon={<PlaylistAdd />}
						size="small"
						onClick={() => setOpenModal(true)}
					>
						Adicionar Serviço{" "}
					</Button>
				</Grid>
			</Grid>

			{data?.map((d) => (
				<>
					<Header
						text={getOptionValueById(
							d.system,
							serviceAttributes.systemOptions
						)}
					/>
					<Table
						style={{ marginBottom: "8px" }}
						stickyHeader
						size="small"
					>
						<TableHead>
							<TableRow>
								<TableCell width={"5%"}></TableCell>
								<TableCell width={"75%"}>Serviço</TableCell>
								<TableCell width={"25%"} align="center">
									Tempo
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{d.services.map((d: any, index: any) => (
								<TableRow
									key={`${d.description
										.replace(" ", "_")
										.toLowerCase()}-${index}`}
								>
									<TableCell>
										<IconButton
											color="default"
											aria-label="delete"
											size="small"
											onClick={() => removeService(d.id)}
										>
											<HighlightOff />
										</IconButton>
									</TableCell>
									<TableCell>{d.description}</TableCell>
									<TableCell align="center">
										{parseDecimalToHoursAndMinutes(
											d.duration
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</>
			))}

			{/* FormModal de adição e edição de serviço */}
			<FormModal
				title="Adicionar serviço"
				description="Adicione um serviço ao template"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="templateServiceFormId"
				actionText="Adicionar Serviço"
			>
				<ServiceTemplateServiceForm
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
					addServiceHandler={addServiceHandler}
				/>
			</FormModal>

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}

export default ServiceTemplateDetails;
