import {
	AccountCircle,
	BuildCircleOutlined,
	PeopleAltOutlined,
	QueryStats,
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import { deepOrange } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu/Menu";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../assets/enginepro.png";
import { appColors } from "../hooks/Contexts/theme";
import { AuthContext } from "../modules/auth/Auth";
import { signOut } from "../services/api/users";

const drawerWidth = 216;

interface IMenuSection {
	description: string;
	routes: {
		text: string;
		icon: React.ReactChild;
		route: string;
		roles: string[];
	}[];
	adminOnly: boolean;
}

const ServicesMenuItems: IMenuSection = {
	description: "Operação",
	adminOnly: false,
	routes: [
		{
			text: "Orçamentos",
			icon: <BuildCircleOutlined htmlColor="#ffffff" fontSize="medium" />,
			route: "/orders",
			roles: ["admin", "orcamentista"],
		},
		// {
		// 	text: "Quadro OS",
		// 	icon: <ViewKanbanIcon fontSize="medium" htmlColor="#ffffff" />,
		// 	route: "/board",
		// 	roles: ["admin", "orcamentista"],
		// },
		// {
		// 	text: "Agendamentos",
		// 	icon: (
		// 		<CalendarMonthOutlinedIcon
		// 			fontSize="medium"
		// 			htmlColor="#ffffff"
		// 		/>
		// 	),
		// 	route: "/agendamentos",
		// 	roles: ["admin", "orcamentista"],
		// },
		// {
		// 	text: 'Pagamentos',
		// 	icon: <SvgIcon svgContent={icons.payments} />,
		// 	route: '/payments',
		// },
	],
};

// const VehicleMenuItems: IMenuSection = {
// 	description: "Cadastros",
// 	routes: [
// {
// 	text: "Modelos",
// 	icon: <DirectionsCarIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/modelos",
// 	roles: ["admin", "mecanico", "orcamentista"],
// },
// {
// 	text: "Serviços",
// 	icon: <CarRepairIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/servicos",
// 	roles: [],
// },
// {
// 	text: "Presets de Serviços",
// 	icon: <AssignmentIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/presets",
// 	roles: [],
// },
// {
// 	text: "Clientes",
// 	icon: (
// 		<SupervisedUserCircle fontSize="medium" htmlColor="#ffffff" />
// 	),
// 	route: "/clientes",
// 	roles: [],
// },
// {
// 	text: "Fornecedores",
// 	icon: <DeliveryDiningIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/fornecedores",
// 	roles: [],
// },
// {
// 	text: "Fornecedores",
// 	icon: <LocalShippingIcon fontSize="large" htmlColor="#ffffff" />,
// 	route: "/fornecedores",
// 	roles: [],
// },
// 	],
// };

const PricingMenuItems: IMenuSection = {
	description: "Precificação",
	adminOnly: true,
	routes: [
		// {
		// 	text: "Financeiro",
		// 	icon: <SwapHorizIcon fontSize="medium" htmlColor="#ffffff" />,
		// 	route: "/financeiro",
		// 	roles: ["it-admin"],
		// },
		{
			text: "Curva de Peças",
			icon: <QueryStats htmlColor="#ffffff" fontSize="medium" />,
			route: "/pricing",
			roles: ["admin", "financeiro"],
		},
		// {
		// 	text: "Definição de Preços",
		// 	icon: <PaidOutlinedIcon htmlColor="#ffffff" fontSize="medium" />,
		// 	route: "/price",
		// 	roles: ["admin", "financeiro"],
		// },
	],
};

// const SupportMenuItems: IMenuSection = {
// 	description: "Suporte",
// 	adminOnly: true,
// 	routes: [
// 		{
// 			text: "Suporte",
// 			icon: <SupportAgent fontSize="medium" htmlColor="#ffffff" />,
// 			route: "/testes",
// 			roles: ["it-admin"],
// 		},
// 	],
// };

const AccountManagement: IMenuSection = {
	description: "Conta",
	adminOnly: true,
	routes: [
		{
			text: "Gestão de Usuários",
			icon: <PeopleAltOutlined fontSize="medium" htmlColor="#ffffff" />,
			route: "/users",
			roles: ["it-admin"],
		},
	],
};

// const AdminMenuItems: IMenuSection = {
// 	description: "Admin",
// 	routes: [
// 		{
// 			text: "Usuários",
// 			icon: <PeopleAltOutlined fontSize="medium" htmlColor="#ffffff" />,
// 			route: "/users",
// 			roles: ["it-admin"],
// 		},
// {
// 	text: "Contingências",
// 	icon: (
// 		<EngineeringOutlinedIcon
// 			htmlColor="#ffffff"
// 			fontSize="medium"
// 		/>
// 	),
// 	route: "/contingencias",
// 	roles: ["it-admin"],
// },
// 	],
// };

const MenuItems = [
	ServicesMenuItems,
	// VehicleMenuItems,
	PricingMenuItems,
	AccountManagement,
	// SupportMenuItems,
	// AdminMenuItems,
];

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: "60px",
	// width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	minHeight: "64px",
	maxHeight: "64px",
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	variants: [
		{
			props: ({ open }) => open,
			style: {
				marginLeft: drawerWidth,
				width: `calc(100% - ${drawerWidth}px)`,
				transition: theme.transitions.create(["width", "margin"], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
			},
		},
	],
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	variants: [
		{
			props: ({ open }) => open,
			style: {
				...openedMixin(theme),
				"& .MuiDrawer-paper": openedMixin(theme),
			},
		},
		{
			props: ({ open }) => !open,
			style: {
				...closedMixin(theme),
				"& .MuiDrawer-paper": closedMixin(theme),
			},
		},
	],
}));

interface IDrawerProps {
	children?: React.ReactNode;
}

export default function MiniDrawer(props: IDrawerProps) {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	let location = useLocation();
	// let navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);

	const { name, surname, companyName, companyTaxId, email, isAdmin } =
		useContext(AuthContext);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	async function handleSignout() {
		try {
			await signOut();
			window.location.reload();
		} catch (error: any) {
			console.error(error);
		}
	}

	const menuItemsList = useMemo(() => {
		return (
			<List>
				{MenuItems.map((itemsArr, index) => {
					if (
						itemsArr.adminOnly !== isAdmin &&
						itemsArr.adminOnly === true
					)
						return <></>;
					return (
						<div key={`section-${index}`}>
							<p style={{ fontSize: "8px" }}>
								{itemsArr.description}
							</p>
							{itemsArr.routes.map((item) => (
								<Link
									key={`link-${item.text}`}
									id={`link-${item.text}`}
									to={item.route}
									style={{
										color: "#fff",
										textDecoration: "none",
									}}
								>
									<ListItemButton
										key={item.text}
										sx={{
											width: "100%",
											":hover": {
												backgroundColor: "#ff7221",
											},
											backgroundColor:
												location.pathname ===
													item.route ||
												(item.route !== "/" &&
													location.pathname.includes(
														item.route
													))
													? "#303030 !important"
													: "",
										}}
									>
										<ListItemIcon
											style={{ marginLeft: "4px" }}
										>
											{item.icon}
										</ListItemIcon>
										<ListItemText primary={item.text} />
									</ListItemButton>
								</Link>
							))}
						</div>
					);
				})}
			</List>
		);
	}, [isAdmin, location.pathname]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar variant="dense">
					<Link to={"/orders"} style={{ marginTop: "14px" }}>
						<img
							src={logoImage}
							alt="Logo Mult Injection"
							height={35}
						/>
					</Link>
					{!open && (
						<IconButton
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							size="small"
							edge="start"
							style={{
								marginTop: "8px",
								marginLeft: "16px",
							}}
						>
							<MenuIcon htmlColor="#ffffff" />
						</IconButton>
					)}
					<div style={{ marginTop: "12px", marginLeft: "auto" }}>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={openMenu}
							onClose={handleClose}
						>
							<Grid
								container
								direction="column"
								style={{ padding: "12px" }}
							>
								<Grid
									direction="row"
									alignItems="top"
									container
									justifyContent="space-between"
									spacing={2}
								>
									<Grid
										container
										direction="column"
										spacing={0}
									>
										<Typography
											variant="h6"
											style={{ padding: 0, margin: 0 }}
										>
											{name ?? ""} {surname ?? ""}
										</Typography>
										<Typography
											color={appColors.text.disabled}
											variant="subtitle2"
											style={{ padding: 0, margin: 0 }}
										>
											{email}
										</Typography>
										<br />
										<Typography
											variant="subtitle2"
											color={appColors.primary.main}
											style={{ padding: 0, margin: 0 }}
										>
											{companyName}
										</Typography>
										<Typography
											variant="subtitle2"
											color={appColors.text.disabled}
											style={{ padding: 0, margin: 0 }}
										>
											{companyTaxId}
										</Typography>
									</Grid>
									<Grid>
										<Avatar
											sx={{ bgcolor: deepOrange[400] }}
										>
											{name?.split(" ")[0].charAt(0)}
											{surname?.split(" ")[0].charAt(0)}
										</Avatar>
									</Grid>
								</Grid>
								<Button
									style={{ justifyContent: "end" }}
									onClick={handleSignout}
								>
									Sair
								</Button>
							</Grid>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />

				<Divider />
				{menuItemsList}
			</Drawer>
			{props.children}
		</Box>
	);
}
