import Box from "@mui/material/Box/Box";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useState } from "react";
import { ActionButtons } from "../../../components/GridActions";
import FormModal from "../../../components/Modals/FormModal";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { removeServiceFromSystem } from "../../../services/api/services";
import { ISystemServiceDto } from "../../../types/Services.types";
import { parseDecimalToHoursAndMinutes } from "../../../utils/helpers/parsers";
import ServiceForm from "./ServiceForm";

interface IServiceGridProps {
	services: ISystemServiceDto[];
	system: string;
	refreshParent: boolean;
	refreshParentHandler: any;
}

function ServicesGrid(props: IServiceGridProps) {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedServiceId, setSelectedServiceId] = useState<string>();
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	function onEditService(serviceId: string) {
		setSelectedServiceId(serviceId);
		setOpenModal(true);
	}

	async function onDelete(service: any) {
		// eslint-disable-next-line no-restricted-globals
		const mustDelete: boolean = confirm(
			"Você tem certeza disso? Esta ação não poderá ser desfeita..."
		);

		if (mustDelete) {
			try {
				await removeServiceFromSystem(service, props.system);
				props.refreshParentHandler(!props.refreshParent);

				setSmartSnackbarConfig({
					open: !smartSnackbarConfig.open,
					message: "Serviço excluído com sucesso",
					severity: "success",
				});
			} catch (error: any) {
				setSmartSnackbarConfig({
					open: !smartSnackbarConfig.open,
					message: error.message as string,
					severity: "error",
				});
				console.error(error);
			}
		} else {
			alert("A ação foi cancelada.");
		}
	}

	const columns: GridColDef[] = [
		{
			field: "description",
			headerName: "Descrição",
			flex: 1,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "duration",
			headerName: "Duração",
			align: "center",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			flex: 0.3,
			renderCell: (obj) => (
				<>
					<p>{parseDecimalToHoursAndMinutes(obj.row.duration)}</p>
				</>
			),
		},
		{
			field: " ",
			headerName: "Ações",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			renderCell: (obj) => (
				<>
					<ActionButtons
						onDelete={() => onDelete(obj.row)}
						onEdit={() => onEditService(obj.row.id)}
					/>
				</>
			),
			flex: 0.7,
			align: "center",
		},
	];

	return (
		<>
			<Box
				sx={{
					height: 300,
					width: "100%",
					"& .super-app-theme--header": {
						backgroundColor: "#ff7221",
					},
				}}
			>
				<DataGrid
					disableRowSelectionOnClick
					density="compact"
					autoHeight
					rows={props.services}
					getRowId={(row: any) =>
						row.description + row.duration.toString()
					}
					columns={columns}
					pageSizeOptions={[15]}
					slots={{
						noRowsOverlay: () => (
							<>
								<Box
									sx={{
										mt: 1,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "flex-end",
										height: "20%",
									}}
								>
									Não há serviços para este modelo
								</Box>
							</>
						),
					}}
				/>
			</Box>

			<FormModal
				title="Editar serviço"
				description="Atualize as informações do serviço"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="serviceForm"
				wide
			>
				<ServiceForm
					id={selectedServiceId!}
					serviceId={selectedServiceId}
					refreshParent={props.refreshParent}
					refreshParentHandler={props.refreshParentHandler}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}
export { ServicesGrid };
