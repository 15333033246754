import { doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { Collections } from "../../Collections";
import { ICompany, IUpdateCompanyDTO } from "../../types/Company.types";
import { companiesRef, db } from "../firestore";
import { getAdminUserByCompanyTaxId } from "./users";

async function getAllCompanies() {
	try {
		var dataSnapshot = await getDocs(companiesRef);

		const companies = dataSnapshot.docs.map((doc) => {
			const data = doc.data();

			return {
				email: data.email,
				phone: data.phone,
				plan: data.plan,
				name: data.name,
				status: data.status,
				address: data.address,
				taxId: data.taxId,
			} as ICompany;
		});

		return companies;
	} catch (error: any) {
		console.error(error);
	}
}

async function createCompany(company: ICompany) {
	try {
		const exists = await getCompanyByTaxId(company.taxId);

		if (!!exists) return;

		await setDoc(doc(db, Collections.companies, company.taxId), company);
	} catch (error: any) {
		console.error(error);
	}
}

async function validateCompanyCreation(companyTaxId: string) {
	try {
		const adminUser = await getAdminUserByCompanyTaxId(companyTaxId);

		if (adminUser) {
			throw new Error(
				"Empresa já possui um Usuário Administrador cadastrado. Entre em contato com o Suporte"
			);
		}

		await updateDoc(doc(db, Collections.companies, companyTaxId), {
			status: "active",
		});
	} catch (error: any) {
		console.error(error);
		throw error;
	}
}

async function getCompanyByTaxId(taxId: string) {
	try {
		var docRef = doc(db, Collections.companies, taxId);
		var dataSnapshot = await getDoc(docRef);

		return dataSnapshot.data();
	} catch (error: any) {
		console.error(error);
	}
}

async function updateCompany(taxId: string, newData: IUpdateCompanyDTO) {
	try {
		var companyRef = doc(db, Collections.companies, taxId);
		updateDoc(companyRef, newData);
	} catch (error: any) {
		console.error(error);
	}
}

export {
	createCompany,
	getAllCompanies,
	getCompanyByTaxId,
	updateCompany,
	validateCompanyCreation,
};
