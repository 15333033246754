import Chip from "@mui/material/Chip/Chip";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useCallback, useEffect, useState } from "react";
import HeaderLine from "../../../components/Content/HeaderLine";
import FormModal from "../../../components/Modals/FormModal";
import { getPrices } from "../../../services/api/prices";
import { IKeyNumberEdition } from "../../../types/KeyNumbers.types";
import PriceForm from "./PriceForm";

export default function Price() {
	const [refresh, setRefresh] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [data, setData] = useState<IKeyNumberEdition[]>([]);

	const getAllPrices = useCallback(async () => {
		try {
			const prices = await getPrices();
			setData(prices!);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getAllPrices();
	}, [getAllPrices, refresh]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	const columns: GridColDef[] = [
		{ field: "author", headerName: "E-mail do responsável", flex: 0.5 },
		{
			field: "createdAt",
			headerName: "Momento da edição",
			flex: 0.5,
			renderCell: (obj: any) => {
				const date = new Date(obj.row.createdAt.seconds * 1000);
				return (
					<>
						{date.toLocaleString("pt-BR", {
							timeZone: "America/Sao_Paulo",
						})}
					</>
				);
			},
		},
		{
			field: "costValue",
			headerName: "Custo por Hora",
			flex: 0.5,
			renderCell: (obj: any) => {
				const value =
					"R$ " +
					parseFloat(obj.row.costValue)
						.toFixed(2)
						.toString()
						.replace(".", ",");
				return <>{value}</>;
			},
		},

		{
			field: "contributionMargin",
			headerName: "Margem de Contribuição",
			flex: 0.5,
			renderCell: (obj: any) => {
				const value =
					parseFloat(obj.row.contributionMargin)
						.toFixed(2)
						.toString()
						.replace(".", ",") + "%";
				return <>{value}</>;
			},
		},

		{
			field: "sellPrice",
			headerName: "Preço da Hora",
			flex: 0.5,
			renderCell: (obj: any) => {
				const value =
					"R$ " +
					parseFloat(obj.row.sellPrice)
						.toFixed(2)
						.toString()
						.replace(".", ",");
				return <>{value}</>;
			},
		},
	];

	return (
		<>
			<HeaderLine
				title="Preço Base"
				buttonText="EDITAR PREÇO"
				buttonAction={() => setOpenModal(true)}
			>
				<>
					<FormModal
						title="Editar Preço Base"
						description="Alterar o valor que é utilizado como base para nossos cálculos de orçamento..."
						isOpen={openModal}
						handleCancel={handleCancel}
						formToSubmitId="priceForm"
					>
						<PriceForm
							id={data[0]?.id ?? ""}
							refreshParent={refresh}
							refreshParentHandler={setRefresh}
							setId={() => {}}
							handleCloseModal={setOpenModal}
						/>
					</FormModal>
					<Chip
						color="primary"
						label={
							data[0]
								? `CUSTO POR HORA: R$ ${Number(
										data[0].costValue
								  ).toFixed(2)}`
								: "Carregando..."
						}
						style={{ fontWeight: "bold" }}
					/>
					<Chip
						color="primary"
						label={
							data[0]
								? `MARGEM DE CONTRIBUIÇÃO: ${Number(
										data[0].contributionMargin
								  ).toFixed(2)}%`
								: "Carregando..."
						}
						style={{ fontWeight: "bold" }}
					/>
					<Chip
						color="primary"
						label={
							data[0]
								? `PREÇO DA HORA: R$ ${Number(
										data[0].sellPrice
								  ).toFixed(2)}`
								: "Carregando..."
						}
						style={{ fontWeight: "bold" }}
					/>
				</>
			</HeaderLine>

			<div style={{ height: 550, width: "100%" }}>
				<div style={{ height: 500, width: "100%" }}>
					<DataGrid
						disableRowSelectionOnClick
						rows={data}
						columns={columns}
						pageSizeOptions={[10]}
					/>
				</div>
			</div>
		</>
	);
}
