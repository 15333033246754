import { IOptions } from "../../types/Options.types";

export function sortOptions(options: IOptions[]) {
	const newOptions = options.sort((a: IOptions, b: IOptions) =>
		a.id.localeCompare(b.id)
	);
	return newOptions;
}

export function sortStringItems(stringItems: string[]) {
	const newOptions = stringItems.sort((a: string, b: string) =>
		a.localeCompare(b)
	);
	return newOptions;
}
