import {
	AddCircle,
	AssignmentTurnedIn,
	DeleteForever,
	History,
	NoteAdd,
	RemoveCircle,
	TipsAndUpdates,
} from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent/TimelineOppositeContent";
import timelineOppositeContentClasses from "@mui/lab/TimelineOppositeContent/timelineOppositeContentClasses";
import TimelineSeparator from "@mui/lab/TimelineSeparator/TimelineSeparator";
import Typography from "@mui/material/Typography/Typography";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../../../../components/Loading";
import { getChangeLogsFromOrder } from "../../../../../../services/api/changeLogs";
import {
	IChangeLog,
	IChangeLogChange,
} from "../../../../../../types/ChangeLog.types";
import {
	formatChangeString,
	parsePropName,
} from "../../../../../../utils/helpers/parsers";

function decideChangeLogIcon(cl: IChangeLog) {
	switch (cl.type) {
		case "remove":
			return <RemoveCircle fontSize="small" />;
		case "add":
			return <AddCircle fontSize="small" />;
		case "delete":
			return <DeleteForever fontSize="small" />;
		case "create":
			return <NoteAdd fontSize="small" />;
		case "update":
			return <TipsAndUpdates fontSize="small" />;
		case "finish":
			return <AssignmentTurnedIn fontSize="small" />;
		case "reopen":
			return <History fontSize="small" />;
		default:
			break;
	}
}

function decideChangeLogTypeTitleText(cl: IChangeLog) {
	switch (cl.type) {
		case "remove":
			return "Remoção";
		case "add":
			return "Adição";
		case "delete":
			return "Exclusão permanente";
		case "create":
			return "Criação";
		case "update":
			return "Atualização";
		case "finish":
			return "Finalização";
		case "reopen":
			return "Reabertura";
		default:
			break;
	}
}

function decideChangeLogTypePhraseText(cl: IChangeLog) {
	switch (cl.type) {
		case "remove":
			return "removeu";
		case "add":
			return "adicionou";
		case "delete":
			return "excluiu para sempre";
		case "create":
			return "criou";
		case "update":
			return "atualizou";
		case "finish":
			return "finalizou";
		case "reopen":
			return "reativou";
		default:
			break;
	}
}

function decideChangeLogIconColor(cl: IChangeLog) {
	switch (cl.type) {
		case "delete":
		case "remove":
			return "error";
		case "add":
		case "create":
		case "finish":
			return "success";
		case "update":
			return "info";
		case "reopen":
			return "warning";
		default:
			break;
	}
}

function decideChangeLogScopePhraseText(cl: IChangeLog) {
	switch (cl.scope) {
		case "part":
			return "a Peça";
		case "service":
			return "o Serviço";
		case "diagnosis":
			return "o Diagnóstico";
		case "order":
			return "a Ordem de Serviço";
		case "partEstimate":
			return "a Cotação de Peça";
		default:
			break;
	}
}

export default function OrderHistory() {
	let { id } = useParams<{ id: string }>();
	const [changes, setChanges] = useState<IChangeLog[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getChangeLogs = useCallback(async () => {
		setLoading(true);
		const data = await getChangeLogsFromOrder(id!);
		const dataChanges = data!.changeLogs;
		setChanges(dataChanges);
		setLoading(false);
	}, [id]);

	useEffect(() => {
		getChangeLogs();
	}, [getChangeLogs]);

	return (
		<>
			{loading && <Loading />}
			{!changes.length ? (
				<Typography variant="h5">
					Não há histórico de alterações
				</Typography>
			) : (
				<Timeline
					sx={{
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 1,
						},
					}}
					style={{
						marginTop: "80px",
						marginBottom: "16px",
						minWidth: "500px",
						maxHeight: "100vh",
						maxWidth: "800px",
					}}
				>
					{changes.map((cl: IChangeLog, i: number) => (
						<TimelineItem key={i} sx={{ minHeight: "auto" }}>
							<TimelineOppositeContent
								sx={{ m: "auto 0" }}
								align="center"
								variant="body2"
								color="text.secondary"
							>
								<Typography variant="h6" component="span">
									{decideChangeLogTypeTitleText(cl)}
								</Typography>
								<br />
								{`${cl.date
									.toDate()
									.toLocaleDateString()} às ${cl.date
									.toDate()
									.getHours()}h${cl.date
									.toDate()
									.getMinutes()}`}
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector />
								<TimelineDot
									color={decideChangeLogIconColor(cl)}
								>
									{decideChangeLogIcon(cl)}
								</TimelineDot>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent sx={{ py: "12px", px: 2 }}>
								<Typography>
									{`${cl.author.name} ${
										cl.author.surname
									} ${decideChangeLogTypePhraseText(
										cl
									)} ${decideChangeLogScopePhraseText(cl)} ${
										cl.targetIdentifier
									}`}
								</Typography>
								{cl.changes?.map((c: IChangeLogChange) => (
									<Typography variant="subtitle2">
										{`• ${parsePropName(c.prop)} de
								${formatChangeString(c.prop, c.oldValue)} para ${formatChangeString(
											c.prop,
											c.newValue
										)}`}
									</Typography>
								))}
							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			)}
		</>
	);
}
