import Alert from "@mui/material/Alert/Alert";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useEffect, useState } from "react";

export interface ISmartSnackbarProps {
	message: string;
	severity: "success" | "error" | "warning" | "info";
	open: boolean;
}

export interface ISmartSnackbarConfig extends ISmartSnackbarProps {}

export default function SmartSnackbar(props: ISmartSnackbarProps) {
	const [open, setOpen] = useState(true);

	useEffect(() => {
		setOpen(true);
	}, [props.open]);

	function handleClose(_event: any, reason: string) {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={open}
			autoHideDuration={5000}
			onClose={handleClose}
		>
			<Alert severity={props.severity}>{props.message}</Alert>
		</Snackbar>
	);
}
