import { IServiceDto } from "../../../../../../types/Services.types";

export interface IServiceFormInput extends Omit<IServiceDto, "id"> {}

export const emptyServiceForm: IServiceFormInput = {
	createdBy: "",
	description: "",
	createdDate: new Date(),
	system: "",
	parts: [],
	duration: 0,
};
