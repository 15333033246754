import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid2";
import { useCallback, useContext, useEffect, useState } from "react";
import CardsGrid from "../../../components/Content/CardsGrid";
import HeaderLine from "../../../components/Content/HeaderLine";
import OSCard from "../../../components/Content/OSCard";
import FormModal from "../../../components/Modals/FormModal";
import { AuthContext } from "../../../modules/auth/Auth";
import {
	getLastOrderIdByCompany,
	getOrders,
} from "../../../services/api/orders";
import { getCurrentPrice } from "../../../services/api/prices";
import { IOrderDto } from "../../../types/Order.types";
import OrderForm from "./OrderForm";

export default function Orders() {
	const { companyTaxId } = useContext(AuthContext);

	const [refresh, setRefresh] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [data, setData] = useState<IOrderDto[]>([]);
	const [showActiveOrders, setShowActiveOrders] = useState<boolean>(true);
	const [sellPrice, setSellPrice] = useState<number>(0);
	const [lastNumericId, setLastNumericId] = useState<number>(0);
	const getLastNumericId = useCallback(async () => {
		try {
			const lastId = await getLastOrderIdByCompany(companyTaxId!);
			setLastNumericId(lastId);
		} catch (error) {
			console.error(error);
		}
	}, [companyTaxId]);

	useEffect(() => {
		getLastNumericId();
	}, [getLastNumericId]);

	const priceData = useCallback(async () => {
		const price = await getCurrentPrice(companyTaxId!);
		setSellPrice(price?.sellPrice ?? 0);
	}, [companyTaxId]);

	useEffect(() => {
		priceData();
	}, [priceData]);

	useEffect(() => {
		async function getAllOrders() {
			try {
				const orders = await getOrders(companyTaxId!, showActiveOrders);
				setData(orders);
			} catch (error: any) {
				console.error(error);
			}
		}

		getAllOrders();
	}, [companyTaxId, refresh, showActiveOrders]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	return (
		<>
			<HeaderLine
				title="Orçamentos"
				buttonText="Criar Orçamento"
				buttonAction={() => setOpenModal(true)}
			>
				<>
					<Grid
						style={{ margin: 0, padding: 0 }}
						direction="row"
						container
						spacing={1}
						size={{ lg: 4 }}
						justifyContent={"center"}
						alignItems="flex-end"
					>
						<Grid>
							<Button
								onClick={() => {
									setShowActiveOrders(!showActiveOrders);
									setRefresh(!refresh);
								}}
								size="small"
								variant="outlined"
								color="primary"
							>
								{`Mostrando: ${
									showActiveOrders ? "Ativos" : "Finalizados"
								}`}
							</Button>
						</Grid>
					</Grid>
				</>
			</HeaderLine>

			<CardsGrid>
				<>
					{data.map((item, index) => {
						return (
							<OSCard price={sellPrice} item={item} key={index} />
						);
					})}
				</>
			</CardsGrid>

			<FormModal
				title={`Criar Orçamento #${lastNumericId + 1}`}
				description="Selecione o veículo para o qual deseja criar um painel de cotações de peças."
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="orderForm"
			>
				<>
					<OrderForm
						refreshParent={refresh}
						refreshParentHandler={setRefresh}
						handleCloseModal={setOpenModal}
					/>
				</>
			</FormModal>
		</>
	);
}
