import { useCallback, useContext, useEffect, useState } from "react";
import CardsGrid from "../../../components/Content/CardsGrid";
import HeaderLine from "../../../components/Content/HeaderLine";
import UserCard from "../../../components/Content/UserCard";
import FormModal from "../../../components/Modals/FormModal";
import { AuthContext } from "../../../modules/auth/Auth";
import { getUsersByCompanyTaxId } from "../../../services/api/users";
import { IUser } from "../../../types/User.types";
import { AddUserForm } from "./AddUserForm";

function Users() {
	const { companyTaxId } = useContext(AuthContext);
	const [users, setUsers] = useState<IUser[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);

	const getUsers = useCallback(async () => {
		try {
			const usersData = await getUsersByCompanyTaxId(companyTaxId!);
			setUsers(usersData ?? []);
		} catch (error) {
			console.error(error);
		}
	}, [companyTaxId]);

	useEffect(() => {
		getUsers();
	}, [getUsers, refresh]);

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	return (
		<>
			<HeaderLine
				title="Gestão de Usuários"
				buttonText="Criar Usuário"
				buttonAction={() => setOpenModal(true)}
			/>

			<CardsGrid>
				<>
					{users.map((item: IUser) => {
						return <UserCard user={item} />;
					})}
				</>
			</CardsGrid>

			<FormModal
				title={`Criar Usuário`}
				description="Adicione um acesso de usuário à sua conta"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="addUserForm"
			>
				<>
					<AddUserForm
						refreshParent={refresh}
						refreshParentHandler={setRefresh}
						handleCloseModal={setOpenModal}
					/>
				</>
			</FormModal>
		</>
	);
}

export { Users };
