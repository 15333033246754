import Grid from "@mui/material/Grid2";
import styled from "styled-components";

interface ITypographyProps {
	text: string;
	subtitle?: string;
	children?: JSX.Element;
	color?: string;
}

const Text = styled.h1`
	color: white;
	font-size: 28px;
	font-weight: bold;
	margin: 0px;
`;

const DisabledHeader = styled.h4<{ $color?: string }>`
	color: ${(props) => props.$color ?? "#A1A1A1"};
	font-size: 14px;
	margin: 0px;
	text-align: left;
`;

const SubTitle = styled.h3`
	color: white;
	font-size: 1rem;
	text-align: left;
	margin: 0px;
`;

const SubTitleRight = styled.h3`
	color: white;
	font-size: 18px;
	text-align: right;
	margin: 0px;
`;

function Title(props: ITypographyProps) {
	return (
		<>
			<Text>{props.text}</Text>
		</>
	);
}

function DiscreteHeader(props: ITypographyProps) {
	return (
		<>
			<Grid justifyContent="space-between" container>
				<DisabledHeader $color={props.color}>
					{props.text}
				</DisabledHeader>
				<DisabledHeader $color={props.color}>
					{props.subtitle ?? ""}
				</DisabledHeader>
			</Grid>
		</>
	);
}

function Header(props: ITypographyProps) {
	return (
		<>
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<SubTitle>{props.text}</SubTitle>
				{props.children && props.children}
			</Grid>
		</>
	);
}

function Footer(props: ITypographyProps) {
	return (
		<>
			<SubTitleRight>{props.text}</SubTitleRight>
		</>
	);
}
export { DiscreteHeader, Footer, Header, Title };
