import { IFipeCodesData } from "../../../types/CarModel.types";

export interface IModelFormInput {
	manufacturerId: string;
	manufacturerName: string;
	manufacturerFipeCode: string;
	insideManufacturerCode: string;
	name: string;
	transmission: string;
	fipeCodes: IFipeCodesData[];
	years: number[];
	fuel: string;
	fipePrice: number;
	engineCapacity: number;
	cilinderAmount: number;
	valvesPerCilinder: number;
	cilindersDistribuition: string;
	feeding: string;
	bodyType: string;
	origin: string;
	bodyCategory: string;
	premium: string;
	dealershipEstimatedPrice: number;
	adjustmentFactor: number;
	version: string;
}

export const emptyModelForm: IModelFormInput = {
	name: "",
	manufacturerName: "",
	manufacturerFipeCode: "",
	insideManufacturerCode: "",
	manufacturerId: "",
	transmission: "",
	fipeCodes: [],
	years: [],
	fuel: "",
	fipePrice: 0,
	engineCapacity: 0,
	cilinderAmount: 0,
	valvesPerCilinder: 0,
	cilindersDistribuition: "",
	feeding: "",
	bodyType: "",
	origin: "",
	bodyCategory: "",
	premium: "",
	dealershipEstimatedPrice: 0,
	adjustmentFactor: 0,
	version: "",
};
