import { collection, getFirestore } from "firebase/firestore";
import { Collections } from "../../Collections";
import { app } from "../config/environments";

const db = getFirestore(app);

const modelsRef = collection(db, Collections.models);
const changeLogsRef = collection(db, Collections.changeLogs);
const ordersRef = collection(db, Collections.orders);
const partsPricingRef = collection(db, Collections.partsPricing);
const pricesRef = collection(db, Collections.prices);
const usersRef = collection(db, Collections.users);
const manufacturersRef = collection(db, Collections.manufacturers);
const companiesRef = collection(db, Collections.companies);

export {
	changeLogsRef,
	companiesRef,
	db,
	manufacturersRef,
	modelsRef,
	ordersRef,
	partsPricingRef,
	pricesRef,
	usersRef,
};
