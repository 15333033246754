interface IIconProps {
	svgContent: string;
	small?: boolean;
	color: string;
}

export default function SvgIcon(props: IIconProps) {
	return (
		<>
			<svg
				height="36px"
				width={props.small ? "27px" : "36px"}
				viewBox={props.small ? "0 -8 54 54" : "0 0 48 48"}
				fill={props.color}
			>
				<path d={props.svgContent} />
			</svg>
		</>
	);
}
