import { IChangeLogChange } from "../../types/ChangeLog.types";

function compareObjects<T extends { [key: string]: any }>(
	oldData: T,
	newData: T
): IChangeLogChange[] {
	const changes: IChangeLogChange[] = [];

	for (const key in newData) {
		if (newData[key] !== oldData[key]) {
			let change: any = {
				prop: key,
				newValue: newData[key],
			};

			if (oldData[key] !== undefined) {
				change.oldValue = oldData[key];
			}

			changes.push(change);
		}
	}

	return changes;
}

function markupPartCalculus(x: number, m: number) {
	return x / (1 - m);
}

function simplePartCalculus(x: number, m: number) {
	return x * (1 + m);
}

export { compareObjects, markupPartCalculus, simplePartCalculus };
