import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import LandingNavBar from "../components/LandingNavBar";
import SpaceContainer from "../components/SpaceContainer";
import { AuthContext } from "../modules/auth/Auth";

export function PublicRoute() {
	const { isAuthenticated } = useContext(AuthContext);

	if (isAuthenticated) {
		return <Navigate to="/orders" />;
	}

	return (
		<>
			<LandingNavBar />
			<SpaceContainer>
				<Outlet />
			</SpaceContainer>
		</>
	);
}
