import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField/TextField";
import { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../components/Loading";
import { PhoneTextMask } from "../../../components/Masks/PhoneTextMask";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { AuthContext } from "../../../modules/auth/Auth";
import { createUser } from "../../../services/api/users";
import { IFormModalProps } from "../../../types/Modal.types";
import { IUser } from "../../../types/User.types";
import { sanitizeMaskedString } from "../../../utils/helpers/parsers";
import {
	AddUserFromInsideEmptyForm,
	IAddUserFromInsideForm,
} from "./AddUserForm.form";

export interface IUserFormProps extends Omit<IFormModalProps, "id"> {}

function AddUserForm(props: IUserFormProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const { control, handleSubmit } = useForm<IAddUserFromInsideForm>({
		defaultValues: AddUserFromInsideEmptyForm,
	});
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const { companyTaxId } = useContext(AuthContext);

	const handleClose = () => {
		props.handleCloseModal(false);
	};

	const onSubmit: SubmitHandler<IAddUserFromInsideForm> = async (
		formData: IAddUserFromInsideForm
	) => {
		setLoading(true);
		try {
			let payload: IUser = {
				name: formData.name,
				surname: formData.surname,
				phone: sanitizeMaskedString(formData.phone),
				password: formData.password,
				email: formData.email,
				isAdmin: false,
				company: companyTaxId!,
			};

			await createUser(payload, true);
			props.refreshParentHandler(!props.refreshParent);
			setLoading(false);
			handleClose();

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Usuário criado com sucesso",
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<>
				<form id="addUserForm" onSubmit={handleSubmit(onSubmit)}>
					<Grid
						container
						spacing={1}
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Grid size={{ xs: 12, sm: 12 }}>
							<Controller
								name="name"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										margin="dense"
										id="name"
										label="Nome"
										variant="outlined"
										type="text"
										fullWidth
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</Grid>

						<Grid size={{ xs: 12, sm: 12 }}>
							<Controller
								name="surname"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="surname"
										label="Sobrenome"
										type="string"
										fullWidth
										variant="outlined"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</Grid>

						<Grid size={{ xs: 12, sm: 12 }}>
							<Controller
								name="email"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="email"
										label="E-mail"
										type="string"
										fullWidth
										variant="outlined"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</Grid>

						<Grid size={{ xs: 12, sm: 12 }}>
							<Controller
								name="phone"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="phone"
										label="Celular"
										type="string"
										fullWidth
										variant="outlined"
										onChange={onChange}
										value={value}
										slotProps={{
											input: {
												inputComponent:
													PhoneTextMask as any,
											},
										}}
									/>
								)}
							/>
						</Grid>

						<Grid size={{ xs: 12, sm: 12 }}>
							<Controller
								name="password"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="password"
										label="Senha"
										type="password"
										fullWidth
										variant="outlined"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</Grid>

						<Grid size={{ xs: 12, sm: 12 }}>
							<Controller
								name="confirmPassword"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="confirmPassword"
										label="Confirmação de Senha"
										type="password"
										fullWidth
										variant="outlined"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</form>
			</>

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { AddUserForm };
