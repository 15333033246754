const isQa =
	window.location.href.includes("qa") ||
	window.location.href.includes("localhost");

const Endpoints = {
	predictions: {
		predict_dealership_price: isQa
			? process.env.REACT_APP_FUNCTIONS_PREDICT_DEALERSHIP_HOUR_PRICE_QA
			: process.env.REACT_APP_FUNCTIONS_PREDICT_DEALERSHIP_HOUR_PRICE,
		predict_adjustment_factor: isQa
			? process.env.REACT_APP_FUNCTIONS_PREDICT_ADJUSTMENT_FACTOR_QA
			: process.env.REACT_APP_FUNCTIONS_PREDICT_ADJUSTMENT_FACTOR,
	},
	apiplacas: {
		getData: (plate: string) =>
			`https://wdapi2.com.br/consulta/${plate}/${
				isQa
					? process.env.REACT_APP_API_PLACAS_TOKEN_QA
					: process.env.REACT_APP_API_PLACAS_TOKEN
			}`,
		getQuotas: `https://wdapi2.com.br/saldo/${
			isQa
				? process.env.REACT_APP_API_PLACAS_TOKEN_QA
				: process.env.REACT_APP_API_PLACAS_TOKEN
		}`,
	},
	fipe: {
		getTabelaReferencia:
			"https://veiculos.fipe.org.br/api/veiculos/ConsultarTabelaDeReferencia",
		getMarcas: "https://veiculos.fipe.org.br/api/veiculos/ConsultarMarcas",
		getModelosPorMarca:
			"https://veiculos.fipe.org.br/api/veiculos/ConsultarModelos",
		getAnosPorModelo:
			"https://veiculos.fipe.org.br/api/veiculos/ConsultarAnoModelo",
		getValorComTodosParametros:
			"https://veiculos.fipe.org.br/api/veiculos/ConsultarValorComTodosParametros",
	},
};

export default Endpoints;
