import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import Breadcrumbs from "@mui/material/Breadcrumbs/Breadcrumbs";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link/Link";
import Tab from "@mui/material/Tab/Tab";
import Typography from "@mui/material/Typography/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../modules/auth/Auth";
import { getModelById } from "../../../../services/api/models";
import { getCurrentPrice } from "../../../../services/api/prices";
import { ICarModelDto } from "../../../../types/CarModel.types";
import { IKeyNumberEdition } from "../../../../types/KeyNumbers.types";
import ModelsForm from "../ModelsForm";
import GroupsTab from "./Tabs/Groups/GroupsTab";
import ServiceTab from "./Tabs/Services/ServiceTab";

export default function EditModel() {
	const { manufacturerId, modelId } = useParams<{
		manufacturerId: string;
		modelId: string;
	}>();
	const id = `${manufacturerId}_${modelId}`;
	const [value, setValue] = useState("1");
	const [model, setModel] = useState<ICarModelDto>();
	const [price, setPrice] = useState<IKeyNumberEdition>();
	const [refresh, setRefresh] = useState<boolean>(false);
	const { companyTaxId } = useContext(AuthContext);

	const getPrice = useCallback(async () => {
		try {
			const price = await getCurrentPrice(companyTaxId!);
			setPrice(price);
		} catch (error: any) {
			console.error(error);
		}
	}, [companyTaxId]);

	useEffect(() => {
		getPrice();
	}, [getPrice]);

	const getModel = useCallback(async () => {
		const model = await getModelById(id);
		setModel(model as ICarModelDto);
	}, [id]);

	useEffect(() => {
		getModel();
	}, [getModel, refresh]);

	const handleChange = (_event: any, newValue: string) => {
		setValue(newValue);
	};

	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
				spacing={1}
			>
				<Grid size={{ xs: 8 }}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link underline="hover" color="inherit" href="/modelos">
							Modelos
						</Link>
						<Typography>{model?.manufacturerName}</Typography>
						<Typography color="primary">{model?.name}</Typography>
					</Breadcrumbs>
				</Grid>
			</Grid>
			<Divider style={{ marginTop: "16px" }} />

			<TabContext value={value}>
				<TabList
					style={{ color: "#ff7221" }}
					onChange={handleChange}
					aria-label="lab API tabs example"
					indicatorColor="primary"
				>
					<Tab label="DADOS DO VEÍCULO" value="1" />
					{/* <Tab label="SERVIÇOS" value="2" /> */}
					{/* <Tab label="GRUPOS" value="3" /> */}
				</TabList>
				<TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="1"
				>
					<ModelsForm id={id} />
				</TabPanel>
				<TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="2"
				>
					<ServiceTab
						id={id}
						price={price?.sellPrice ?? 0}
						services={model?.services ?? []}
						refreshParent={refresh}
						refreshParentHandler={setRefresh}
						adjustmentFactor={model?.adjustmentFactor ?? 0}
						serviceGroups={model?.serviceGroups ?? []}
					/>
				</TabPanel>
				<TabPanel
					style={{
						padding: "16px 0px",
					}}
					value="3"
				>
					<GroupsTab
						id={id}
						groups={model?.serviceGroups ?? []}
						refreshParent={refresh}
						refreshParentHandler={setRefresh}
						services={model?.services ?? []}
						adjustmentFactor={model?.adjustmentFactor}
					/>
				</TabPanel>
			</TabContext>
		</>
	);
}
