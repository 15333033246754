import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TextField from "@mui/material/TextField/TextField";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import HeaderLine from "../../../components/Content/HeaderLine";
import Loading from "../../../components/Loading";
import { IPaymentFormInput } from "./PaymentsForm.form";

interface IPossibleDueAmounts {
	nowPix: number;
	nowDebit: number;
	nowCredit: number;
	creditTillSix: IPossibleInstallment[];
}

interface IPossibleInstallment {
	installments: number;
	amount: number;
}

const SmallDisclaimer = styled.p`
	font-size: 10px;
	text-align: left;
`;

function Payments() {
	const { control, getValues } = useForm<IPaymentFormInput>();
	const [loading, setLoading] = useState<boolean>(false);
	const [values, setValues] = useState<IPossibleDueAmounts>();

	function calculate() {
		const data = getValues();
		setLoading(true);

		let possibleInstallments: IPossibleInstallment[] = [];
		for (let i = 3; i <= 6; i++) {
			possibleInstallments.push({
				amount: parseFloat((data.amount / i).toFixed(2)),
				installments: i,
			});
		}

		const possibleAmounts: IPossibleDueAmounts = {
			nowPix: parseFloat((data.amount / 1.032).toFixed(2)), // 0% de taxa, 3,20% de desconto
			nowDebit: parseFloat((data.amount / 1.017).toFixed(2)), // 1,5% de taxa, 1,7% de desconto
			nowCredit: parseFloat((data.amount / 1.0021).toFixed(2)), // 2,99% de taxa, 0,21% de desconto
			creditTillSix: possibleInstallments, // 3,20% de taxa, 0% de desconto
		};

		setValues(possibleAmounts);
		setLoading(false);
	}

	return (
		<>
			{loading && <Loading />}

			<HeaderLine
				title="Condições de Pagamento"
				buttonText="Calcular"
				buttonAction={() => calculate()}
			/>
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<Grid size={{ xs: 12, sm: 12 }}>
					<form id="paymentForm">
						<Controller
							name="amount"
							control={control}
							defaultValue={0}
							render={({ field: { onChange, value } }) => (
								<TextField
									autoFocus
									margin="dense"
									id="amount"
									type="number"
									fullWidth
									InputProps={{
										inputProps: {
											min: 0,
											step: "0.01",
										},
										startAdornment: {
											...(
												<InputAdornment position="start">
													R$
												</InputAdornment>
											),
										},
									}}
									onChange={onChange}
									value={value === 0 ? "" : value}
								/>
							)}
						/>
					</form>
				</Grid>

				{values && (
					<>
						<Grid size={{ xs: 8, sm: 8 }}>
							<TextField
								label="Pix ou Dinheiro"
								margin="dense"
								size="small"
								disabled
								id="nowPix"
								type="number"
								fullWidth
								InputProps={{
									startAdornment: {
										...(
											<InputAdornment position="start">
												1x de R$
											</InputAdornment>
										),
									},
								}}
								value={values.nowPix.toFixed(2)}
							/>
						</Grid>
						<Grid size={{ xs: 8, sm: 8 }}>
							<TextField
								label="Débito à Vista"
								margin="dense"
								size="small"
								disabled
								id="nowDebit"
								type="number"
								fullWidth
								InputProps={{
									startAdornment: {
										...(
											<InputAdornment position="start">
												1x de R$
											</InputAdornment>
										),
									},
								}}
								value={values.nowDebit.toFixed(2)}
							/>
						</Grid>
						<Grid size={{ xs: 8, sm: 8 }}>
							<TextField
								label="Cédito à Vista"
								margin="dense"
								size="small"
								disabled
								id="nowCredit"
								type="number"
								fullWidth
								InputProps={{
									startAdornment: {
										...(
											<InputAdornment position="start">
												1x de R$
											</InputAdornment>
										),
									},
								}}
								value={values.nowCredit.toFixed(2)}
							/>
						</Grid>
						<Grid size={{ xs: 8, sm: 8 }}>
							{values.creditTillSix.map(
								(possibility: IPossibleInstallment) => (
									<>
										<TextField
											margin="dense"
											size="small"
											disabled
											label={
												possibility.installments === 3
													? "Crédito Parcelado"
													: ""
											}
											id="nowPix"
											type="number"
											fullWidth
											InputProps={{
												startAdornment: {
													...(
														<InputAdornment position="start">
															{`${possibility.installments}x de R$`}
														</InputAdornment>
													),
												},
											}}
											value={possibility.amount.toFixed(
												2
											)}
										/>
									</>
								)
							)}
						</Grid>
					</>
				)}
				<Grid size={{ xs: 8, sm: 8 }}>
					<SmallDisclaimer>
						* Os valores são calculados com base nas taxas da
						máquina PagBank no ano corrente de 2023
					</SmallDisclaimer>
				</Grid>
			</Grid>
		</>
	);
}

export { Payments };
