import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import MiniDrawer from "../components/MiniDrawer";
import SpaceContainer from "../components/SpaceContainer";
import { AuthContext } from "../modules/auth/Auth";

export function ProtectedRoute() {
	const { isAuthenticated } = useContext(AuthContext);

	if (!isAuthenticated) {
		return <Navigate to="/" />;
	}

	return (
		<MiniDrawer>
			<SpaceContainer>
				<Outlet />
			</SpaceContainer>
		</MiniDrawer>
	);
}
