import createTheme from "@mui/material/styles/createTheme";

const appColors = {
	primary: {
		main: "#ff7221",
		light: "#ffb426",
		contrast: "#ffffff",
	},
	secondary: {
		main: "#f50057",
	},
	background: {
		default: "#303030",
		paper: "#424242",
	},
	text: {
		primary: "#FFFFFF",
		secondary: "#FFFFFF",
		disabled: "#ABABAB",
	},
};

const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: appColors.primary.main,
			light: appColors.primary.light,
			contrastText: appColors.primary.contrast,
		},
		secondary: {
			main: appColors.secondary.main,
		},
		background: {
			default: appColors.background.default,
			paper: appColors.background.paper,
		},
		text: {
			primary: appColors.text.primary,
			secondary: appColors.text.secondary,
			disabled: appColors.text.disabled,
		},
	},
});

export { appColors, theme };
