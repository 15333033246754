import { GridLocaleText } from "@mui/x-data-grid";

export const GRID_DEFAULT_LOCALE_TEXT: GridLocaleText = {
	// Raiz
	noRowsLabel: "Nenhuma linha",
	noResultsOverlayLabel: "Nenhum resultado encontrado.",

	// Texto do botão da barra de ferramentas de seleção de densidade
	toolbarDensity: "Densidade",
	toolbarDensityLabel: "Densidade",
	toolbarDensityCompact: "Compacto",
	toolbarDensityStandard: "Padrão",
	toolbarDensityComfortable: "Confortável",

	// Texto do botão da barra de ferramentas de seleção de colunas
	toolbarColumns: "Colunas",
	toolbarColumnsLabel: "Selecionar colunas",

	// Texto do botão da barra de ferramentas de filtros
	toolbarFilters: "Filtros",
	toolbarFiltersLabel: "Exibir filtros",
	toolbarFiltersTooltipHide: "Ocultar filtros",
	toolbarFiltersTooltipShow: "Exibir filtros",
	toolbarFiltersTooltipActive: (count: any) =>
		count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

	// Campo de filtro rápido na barra de ferramentas
	toolbarQuickFilterPlaceholder: "Pesquisar...",
	toolbarQuickFilterLabel: "Pesquisar",
	toolbarQuickFilterDeleteIconLabel: "Limpar",

	// Texto do botão da barra de ferramentas de exportação
	toolbarExport: "Exportar",
	toolbarExportLabel: "Exportar",
	toolbarExportCSV: "Baixar como CSV",
	toolbarExportPrint: "Imprimir",
	toolbarExportExcel: "Baixar como Excel",

	// Texto de gerenciamento de colunas
	columnsManagementSearchTitle: "Pesquisar",
	columnsManagementNoColumns: "Nenhuma coluna",
	columnsManagementShowHideAllText: "Mostrar/Ocultar tudo",
	columnsManagementReset: "Redefinir",

	// Texto do painel de filtros
	filterPanelAddFilter: "Adicionar filtro",
	filterPanelRemoveAll: "Remover todos",
	filterPanelDeleteIconLabel: "Excluir",
	filterPanelLogicOperator: "Operador lógico",
	filterPanelOperator: "Operador",
	filterPanelOperatorAnd: "E",
	filterPanelOperatorOr: "Ou",
	filterPanelColumns: "Colunas",
	filterPanelInputLabel: "Valor",
	filterPanelInputPlaceholder: "Valor do filtro",

	// Texto dos operadores de filtro
	filterOperatorContains: "contém",
	filterOperatorDoesNotContain: "não contém",
	filterOperatorEquals: "igual a",
	filterOperatorDoesNotEqual: "diferente de",
	filterOperatorStartsWith: "começa com",
	filterOperatorEndsWith: "termina com",
	filterOperatorIs: "é",
	filterOperatorNot: "não é",
	filterOperatorAfter: "é depois de",
	filterOperatorOnOrAfter: "é em ou depois de",
	filterOperatorBefore: "é antes de",
	filterOperatorOnOrBefore: "é em ou antes de",
	filterOperatorIsEmpty: "está vazio",
	filterOperatorIsNotEmpty: "não está vazio",
	filterOperatorIsAnyOf: "é qualquer um de",
	"filterOperator=": "=",
	"filterOperator!=": "!=",
	"filterOperator>": ">",
	"filterOperator>=": ">=",
	"filterOperator<": "<",
	"filterOperator<=": "<=",

	// Texto dos operadores de filtro no cabeçalho
	headerFilterOperatorContains: "Contém",
	headerFilterOperatorDoesNotContain: "Não contém",
	headerFilterOperatorEquals: "Igual a",
	headerFilterOperatorStartsWith: "Começa com",
	headerFilterOperatorDoesNotEqual: "Diferente de",
	headerFilterOperatorEndsWith: "Termina com",
	headerFilterOperatorIs: "É",
	headerFilterOperatorNot: "Não é",
	headerFilterOperatorAfter: "É depois de",
	headerFilterOperatorOnOrAfter: "É em ou depois de",
	headerFilterOperatorBefore: "É antes de",
	headerFilterOperatorOnOrBefore: "É em ou antes de",
	headerFilterOperatorIsEmpty: "Está vazio",
	headerFilterOperatorIsNotEmpty: "Não está vazio",
	headerFilterOperatorIsAnyOf: "É qualquer um de",
	"headerFilterOperator=": "Igual a",
	"headerFilterOperator!=": "Diferente de",
	"headerFilterOperator>": "Maior que",
	"headerFilterOperator>=": "Maior ou igual a",
	"headerFilterOperator<": "Menor que",
	"headerFilterOperator<=": "Menor ou igual a",

	// Texto dos valores de filtro
	filterValueAny: "qualquer",
	filterValueTrue: "verdadeiro",
	filterValueFalse: "falso",

	// Texto do menu de colunas
	columnMenuLabel: "Menu",
	columnMenuShowColumns: "Mostrar colunas",
	columnMenuManageColumns: "Gerenciar colunas",
	columnMenuFilter: "Filtrar",
	columnMenuHideColumn: "Ocultar coluna",
	columnMenuUnsort: "Desfazer ordenação",
	columnMenuSortAsc: "Ordenar por ASC",
	columnMenuSortDesc: "Ordenar por DESC",

	// Texto do cabeçalho de colunas
	columnHeaderFiltersTooltipActive: (count: any) =>
		count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
	columnHeaderFiltersLabel: "Exibir filtros",
	columnHeaderSortIconLabel: "Ordenar",

	// Texto do rodapé de linhas selecionadas
	footerRowSelected: (count: any) =>
		count !== 1
			? `${count.toLocaleString()} linhas selecionadas`
			: `${count.toLocaleString()} linha selecionada`,

	// Texto do total de linhas no rodapé
	footerTotalRows: "Total de Linhas:",

	// Texto do total de linhas visíveis no rodapé
	footerTotalVisibleRows: (visibleCount: any, totalCount: any) =>
		`${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

	// Texto de seleção de checkbox
	checkboxSelectionHeaderName: "Seleção por checkbox",
	checkboxSelectionSelectAllRows: "Selecionar todas as linhas",
	checkboxSelectionUnselectAllRows: "Desselecionar todas as linhas",
	checkboxSelectionSelectRow: "Selecionar linha",
	checkboxSelectionUnselectRow: "Desselecionar linha",

	// Texto das células booleanas
	booleanCellTrueLabel: "sim",
	booleanCellFalseLabel: "não",

	// Texto adicional de ações
	actionsCellMore: "mais",

	// Texto de fixação de colunas
	pinToLeft: "Fixar à esquerda",
	pinToRight: "Fixar à direita",
	unpin: "Desfixar",

	// Dados em árvore
	treeDataGroupingHeaderName: "Agrupar",
	treeDataExpand: "ver filhos",
	treeDataCollapse: "ocultar filhos",

	// Agrupamento de colunas
	groupingColumnHeaderName: "Agrupar",
	groupColumn: (name: any) => `Agrupar por ${name}`,
	unGroupColumn: (name: any) => `Desagrupar por ${name}`,

	// Mestre/detalhe
	detailPanelToggle: "Alternar painel de detalhes",
	expandDetailPanel: "Expandir",
	collapseDetailPanel: "Recolher",

	// Used core components translation keys
	MuiTablePagination: {},

	// Reordenação de linhas
	rowReorderingHeaderName: "Reordenar linhas",

	// Agregação
	aggregationMenuItemHeader: "Agregação",
	aggregationFunctionLabelSum: "soma",
	aggregationFunctionLabelAvg: "média",
	aggregationFunctionLabelMin: "mínimo",
	aggregationFunctionLabelMax: "máximo",
	aggregationFunctionLabelSize: "tamanho",
};
