import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from "firebase/auth";
import "firebase/compat/auth";
import {
	doc,
	getDoc,
	getDocs,
	limit,
	query,
	setDoc,
	where,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import { IUser } from "../../types/User.types";
import { auth, firebaseConfig } from "../config/environments";
import { db, usersRef } from "../firestore";
import { validateCompanyCreation } from "./companies";

import { deleteApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

async function getAllUsers() {
	try {
		var dataSnapshot = await getDocs(usersRef);

		dataSnapshot.forEach(() => {});
	} catch (error: any) {
		console.error(error);
	}
}

async function getUserByUuid(uuid: string) {
	try {
		var docRef = doc(db, Collections.users, uuid);
		var dataSnapshot = await getDoc(docRef);

		return dataSnapshot.data();
	} catch (error: any) {
		console.error(error);
	}
}

async function authenticate(email: string, password: string) {
	try {
		await signInWithEmailAndPassword(auth, email, password);
	} catch (error: any) {
		console.error(error);
	}
}

async function signOut() {
	try {
		await auth.signOut();
	} catch (error: any) {
		console.error(error);
	}
}

async function createUser(user: IUser, createFromAdmin?: boolean) {
	try {
		if (user.isAdmin) {
			await validateCompanyCreation(user.company);
		}

		let newUser;

		if (!!createFromAdmin) {
			const secondInstance = initializeApp(firebaseConfig, "Secondary");
			const auth2 = getAuth(secondInstance);
			newUser = await createUserWithEmailAndPassword(
				auth2,
				user.email,
				user.password
			);
			deleteApp(secondInstance);
		} else {
			newUser = await createUserWithEmailAndPassword(
				auth,
				user.email,
				user.password
			);
		}

		await setDoc(doc(db, Collections.users, newUser!.user!.uid), {
			name: user.name,
			surname: user.surname,
			email: user.email,
			phone: user.phone,
			company: user.company,
			isAdmin: user.isAdmin,
		});
	} catch (error: any) {
		console.error(error);
		throw error;
	}
}

async function getAdminUserByCompanyTaxId(companyTaxId: string) {
	try {
		let q = query(
			usersRef,
			where("company", "==", companyTaxId),
			where("isAdmin", "==", true),
			limit(1)
		);
		var dataSnapshot = await getDocs(q);

		let users = dataSnapshot.docs.map((doc) => {
			const data = doc.data();

			return {
				name: data.name,
				surname: data.surname,
				email: data.email,
				company: data.company,
				isAdmin: data.isAdmin,
			} as IUser;
		});

		return users[0];
	} catch (error: any) {
		console.error(error);
	}
}

async function getUsersByCompanyTaxId(companyTaxId: string) {
	try {
		let q = query(usersRef, where("company", "==", companyTaxId));
		var dataSnapshot = await getDocs(q);

		let users = dataSnapshot.docs.map((doc) => {
			const data = doc.data();

			return {
				name: data.name,
				surname: data.surname,
				email: data.email,
				company: data.company,
				isAdmin: data.isAdmin,
			} as IUser;
		});

		return users;
	} catch (error: any) {
		console.error(error);
	}
}

export {
	authenticate,
	createUser,
	getAdminUserByCompanyTaxId,
	getAllUsers,
	getUserByUuid,
	getUsersByCompanyTaxId,
	signOut,
};
