import { uuidv4 } from "@firebase/util";
import {
	AddShoppingCart,
	Cancel,
	CheckCircle,
	Delete,
	DeliveryDining,
	Equalizer,
	Save,
	WhatsApp,
	ZoomOutMap,
} from "@mui/icons-material";
import Button from "@mui/material/Button/Button";
import Chip from "@mui/material/Chip/Chip";
import Drawer from "@mui/material/Drawer/Drawer";
import Grid from "@mui/material/Grid2";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid/colDef/gridCheckboxSelectionColDef";
import { GridActionsCellItem } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridRowModesModel } from "@mui/x-data-grid/models/api/gridEditingApi";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridEventListener } from "@mui/x-data-grid/models/events/gridEventListener";
import { GridRowModes } from "@mui/x-data-grid/models/gridEditRowModel";
import { GridRowId, GridRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel";
import { GridRowEditStopReasons } from "@mui/x-data-grid/models/params/gridRowParams";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import EditableOrderParts from "../../../../../../components/Content/EditableOrderPartsGrid";
import PartsSummary from "../../../../../../components/Content/PartsSummary";
import Loading from "../../../../../../components/Loading";
import FormModal from "../../../../../../components/Modals/FormModal";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { GRID_DEFAULT_LOCALE_TEXT } from "../../../../../../hooks/Contexts/gridLocale";
import { appColors } from "../../../../../../hooks/Contexts/theme";
import { AuthContext } from "../../../../../../modules/auth/Auth";
import { getCompanyByTaxId } from "../../../../../../services/api/companies";
import {
	deleteOrderPart,
	getOrder,
	updatePartsBatch,
	upsertPartInOrder,
} from "../../../../../../services/api/orders";
import { IChangeLogBase } from "../../../../../../types/ChangeLog.types";
import { ICompany } from "../../../../../../types/Company.types";
import { IOrderDto } from "../../../../../../types/Order.types";
import {
	IOrderPartsDto,
	IPartEstimateDto,
	IPartEstimateOnSummaryDto,
	IPartSummarySupplier,
	IPartsSummary,
} from "../../../../../../types/Parts.types";
import {
	setPartStatusColor,
	setPartStatusText,
	setPartStatusTextBack,
} from "../../../../../../utils/helpers/parsers";

interface IOrderPartsDetailsProps {
	modelDescription: string;
	modelPlate: string;
	refreshParentHandler: any;
}

export default function OrderPartsDetails(props: IOrderPartsDetailsProps) {
	let { id } = useParams<{ id: string }>();
	const { companyTaxId, name, surname, email } = useContext(AuthContext);
	const [order, setOrder] = useState<IOrderDto>();
	const [parts, setParts] = useState<IOrderPartsDto[]>([]);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [refreshSum, setRefreshSum] = useState<boolean>(false);
	const [companyData, setCompanyData] = useState<ICompany>();
	const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
	const [partDetailsOpen, setPartDetailsOpen] = useState<boolean>(false);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const [summary, setSummary] = useState<IPartsSummary>({ suppliers: [] });
	const [rowSelectionModel, setRowSelectionModel] =
		useState<GridRowSelectionModel>([]);

	useEffect(() => {
		let sum = 0;

		order?.parts?.forEach((p: IOrderPartsDto) => {
			p.estimates.forEach((e: IPartEstimateDto) => {
				if (e.isBestOption) {
					sum += e.sellUnitPrice * e.amount;
				}
			});
		});

		props.refreshParentHandler(sum);
	}, [order?.parts, props, refreshSum]);

	const selectedPart = useRef<IOrderPartsDto | undefined>();
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleDeleteClick = (id: string) => async () => {
		try {
			await removeOrderPart(id);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});
	};

	const handleDetailsClick = (i: GridRowId) => () => {
		const part = parts!.find((p: IOrderPartsDto) => i === p.id);
		selectedPart.current = part;
		setPartDetailsOpen(true);
	};

	const columns: GridColDef[] = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			headerClassName: "super-app-theme--header",
			width: 60,
		},
		{
			field: "description",
			headerName: "Item",
			editable: true,
			flex: 0.4,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "originalCode",
			headerName: "Código Referência",
			editable: true,
			flex: 0.2,
			align: "center",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			renderCell: (obj) => <>{obj.row.originalCode ?? "N/A"}</>,
		},
		{
			field: "status",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			type: "singleSelect",
			valueOptions: ["Orçar", "A Caminho", "Recebida"],
			align: "center",
			headerName: "Status",
			editable: true,
			flex: 0.2,
			renderCell: (obj) => (
				<>
					<Chip
						label={setPartStatusText(obj.row.status ?? "")}
						color={setPartStatusColor(obj.row.status ?? "")}
						size="small"
					/>{" "}
				</>
			),
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Ações",
			flex: 0.2,
			cellClassName: "actions",
			headerClassName: "super-app-theme--header",
			getActions: ({ id }) => {
				const isInEditMode =
					rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<Save />}
							label="Save"
							sx={{
								color: "primary.main",
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<Cancel />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<ZoomOutMap />}
						label="Edit"
						className="textPrimary"
						onClick={handleDetailsClick(id)}
						color="inherit"
					/>,
					<GridActionsCellItem
						icon={<Delete />}
						label="Delete"
						onClick={handleDeleteClick(id as string)}
						color="inherit"
					/>,
				];
			},
		},
	];

	const getCompanyData = useCallback(async () => {
		try {
			const data = await getCompanyByTaxId(companyTaxId!);
			setCompanyData(data as ICompany);
		} catch (error: any) {
			console.error(error);
		}
	}, [companyTaxId]);

	useEffect(() => {
		getCompanyData();
	}, [getCompanyData, refresh]);

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return;
			}

			setSummaryOpen(open);
		};

	useEffect(() => {
		const estimates = parts?.flatMap((order) =>
			order.estimates.map((estimate) => ({
				...estimate,
				description: order.description,
			}))
		) as IPartEstimateOnSummaryDto[];

		// Filtra as partes que têm a flag isBestOption como true
		const bestOptionParts = estimates?.filter((part) => part.isBestOption);

		// Agrupa as partes pelo nome do fornecedor (provider)
		const suppliersMap: { [key: string]: IPartEstimateOnSummaryDto[] } = {};
		bestOptionParts?.forEach((part) => {
			if (!suppliersMap[part.provider]) {
				suppliersMap[part.provider] = [];
			}
			suppliersMap[part.provider].push(part);
		});

		// Transforma o mapa de fornecedores em um array de suppliers
		const suppliersResult: IPartSummarySupplier[] = Object.keys(
			suppliersMap
		).map((provider) => ({
			name: provider,
			parts: suppliersMap[provider],
			deliveryTax: 0,
		}));

		setSummary({ suppliers: suppliersResult });
	}, [parts]);

	async function removeOrderPart(partId: string) {
		try {
			setLoading(true);
			const changeLog: IChangeLogBase = {
				targetIdentifier: ``,
				author: {
					email: email!,
					name: name!,
					surname: surname!,
				},
				scope: "part",
				type: "remove",
				date: new Date(),
			};
			await deleteOrderPart(id!, partId, changeLog);
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	function handleCloseEstimatesModal() {
		setPartDetailsOpen(false);
		selectedPart.current = undefined;
	}

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const handleRowEditStop: GridEventListener<"rowEditStop"> = (
		params,
		event
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const orderData = useCallback(async () => {
		try {
			const data = await getOrder(id!);
			setOrder(data as IOrderDto);
		} catch (error: any) {
			console.error(error);
		}
	}, [id]);

	useEffect(() => {
		orderData();
	}, [orderData, refresh, refreshSum]);

	useEffect(() => {
		if (order?.parts) {
			setParts(order!.parts);
		}
	}, [order?.parts, order]);

	async function generateWhatsAppMessage() {
		let itens = "";
		parts?.forEach((p: IOrderPartsDto) => {
			if (p.status === "ask") {
				itens =
					itens +
					`• ${p.description} ${
						p.originalCode ? `(Cód. ${p.originalCode})` : ""
					}\n`;
			}
		});
		const message = `Olá, tudo bem?\nPara um ${order?.manufacturer} ${order?.model} ${order?.year} você tem os seguintes itens?\n\n${itens}\nPlaca: ${props.modelPlate}`;
		await navigator.clipboard.writeText(message);

		setSmartSnackbarConfig({
			open: !smartSnackbarConfig.open,
			message: `Mensagem copiada`,
			severity: "success",
		});
	}

	const handleAddPart = () => {
		const id = uuidv4();
		setParts((parts) => [
			...parts,
			{
				id,
				description: "",
				status: "ask",
				estimates: [],
			},
		]);
		setRowModesModel((oldModel: any) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "description" },
		}));
	};

	const processRowUpdate = async (
		newRow: GridRowModel,
		oldRow: GridRowModel
	) => {
		setLoading(true);
		try {
			const payload = {
				status: ["ask", "requested", "delivered"].includes(
					newRow.status
				)
					? newRow.status
					: setPartStatusTextBack(newRow.status),
				description: newRow.description,
				originalCode: newRow.originalCode ?? "",
				estimates: [],
				id: newRow.id,
			} as IOrderPartsDto;

			const changeLog: IChangeLogBase = {
				targetIdentifier: newRow.description,
				author: {
					email: email!,
					name: name!,
					surname: surname!,
				},
				scope: "part",
				type: oldRow.description === "" ? "add" : "update",
				date: new Date(),
			};

			await upsertPartInOrder(id!, newRow.id, payload, changeLog);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: `Alteração realizada com sucesso`,
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			setRefresh(!refresh);
		}

		const updatedRow = { ...newRow, isNew: false };
		setParts(
			parts.map((row: any) => (row.id === newRow.id ? updatedRow : row))
		);
		return updatedRow;
	};

	const saveSatusBatchEdition = async (
		newStatus: "requested" | "delivered"
	) => {
		try {
			setLoading(true);
			let newParts = parts.map((p: IOrderPartsDto) => {
				if (rowSelectionModel.includes(p.id)) {
					return { ...p, status: newStatus };
				}
				return p;
			});

			await updatePartsBatch(id!, newParts);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: `Alteração realizada com sucesso`,
				severity: "success",
			});

			setRefresh(!refresh);
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});

			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{loading && <Loading />}
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
				style={{ marginBottom: "8px" }}
			>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-end"
					spacing={1}
					size={{ sm: 6, md: 6, lg: 6 }}
				>
					{!!rowSelectionModel.length && (
						<>
							<Grid>
								<Button
									endIcon={<DeliveryDining />}
									variant="contained"
									color="info"
									size="small"
									onClick={() =>
										saveSatusBatchEdition("requested")
									}
								>
									Marcar como Solicitado
								</Button>
							</Grid>

							<Grid>
								<Button
									endIcon={<CheckCircle />}
									variant="contained"
									color="success"
									size="small"
									onClick={() =>
										saveSatusBatchEdition("delivered")
									}
								>
									Marcar como Recebido
								</Button>
							</Grid>
						</>
					)}
				</Grid>

				<Grid
					container
					direction="row"
					justifyContent="flex-end"
					alignItems="flex-end"
					spacing={1}
					size={{ sm: 6, md: 6, lg: 6 }}
				>
					{summary!.suppliers.length > 0 && (
						<Grid>
							<Button
								endIcon={<Equalizer />}
								variant="contained"
								color="primary"
								size="small"
								onClick={toggleDrawer(true)}
							>
								Resumo
							</Button>
							<Drawer
								anchor={"right"}
								open={summaryOpen}
								onClose={toggleDrawer(false)}
							>
								<PartsSummary
									companyPartsPricing={
										companyData?.partsPricing!
									}
									suppliers={summary?.suppliers ?? []}
								/>
							</Drawer>
						</Grid>
					)}

					<Grid>
						<Button
							variant="contained"
							color="primary"
							endIcon={<AddShoppingCart />}
							size="small"
							onClick={() => handleAddPart()}
						>
							Adicionar Peça{" "}
						</Button>
					</Grid>

					{!!parts?.length && (
						<Grid>
							<Button
								variant="contained"
								color="success"
								endIcon={<WhatsApp />}
								size="small"
								onClick={() => generateWhatsAppMessage()}
							>
								Copiar Mensagem
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>

			<br />

			{!!parts?.length && (
				<DataGrid
					rows={parts ?? []}
					density="compact"
					columns={columns}
					editMode="row"
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 50,
							},
						},
						sorting: {
							sortModel: [{ field: "status", sort: "asc" }],
						},
					}}
					sx={{
						maxHeight: "500px",
						minHeight: "500px",
						"& .super-app-theme--header": {
							backgroundColor: appColors.background.paper,
						},
					}}
					pageSizeOptions={[15, 50]}
					rowModesModel={rowModesModel}
					onRowModesModelChange={handleRowModesModelChange}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					checkboxSelection
					disableRowSelectionOnClick
					localeText={GRID_DEFAULT_LOCALE_TEXT}
					onRowSelectionModelChange={(newRowSelectionModel) => {
						setRowSelectionModel(newRowSelectionModel);
					}}
					rowSelectionModel={rowSelectionModel}
				/>
			)}

			{!!selectedPart.current && (
				<FormModal
					title={`Cotações para ${
						selectedPart.current?.description ?? ""
					}`}
					description={`Tabela de comparação de opções`}
					isOpen={partDetailsOpen}
					ultraWide
					handleCancel={handleCloseEstimatesModal}
					hideSaveButton
					cancelText="Fechar"
				>
					<EditableOrderParts
						fullView={true}
						partsPricing={companyData?.partsPricing!}
						orderId={id!}
						part={selectedPart.current!}
						refreshParent={refreshSum}
						refreshParendHandler={setRefreshSum}
						partName={selectedPart.current?.description ?? ""}
					/>
				</FormModal>
			)}
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}
