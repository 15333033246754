import { IOptions } from "../../types/Options.types";

const cilindersDistribuitionOptions: IOptions[] = [
	{ id: "Linha", value: "Linha" },
	{ id: "V", value: "V" },
	{ id: "Contrapostos", value: "Contrapostos" },
];

const engineFeedingOptions: IOptions[] = [
	{ id: "Aspirado", value: "Aspirado" },
	{ id: "Turbo", value: "Turbo" },
	{ id: "Biturbo", value: "Biturbo" },
	{ id: "Compressor Mecânico", value: "Compressor Mecânico" },
];

const fuelOptions: IOptions[] = [
	{ id: "Gasolina", value: "Gasolina" },
	{ id: "Flex", value: "Flex" },
	{ id: "Diesel", value: "Diesel" },
	{ id: "Elétrico", value: "Elétrico" },
	{ id: "Híbrido", value: "Híbrido" },
];

const trasnmissionOptions: IOptions[] = [
	{ id: "Manual", value: "Manual" },
	{ id: "Automática", value: "Automática" },
	{ id: "CVT", value: "CVT" },
	{ id: "DCT", value: "DCT" },
	{ id: "Automatizada", value: "Automatizada" },
];

const originOptions: IOptions[] = [
	{ id: "Nacional", value: "Nacional" },
	{ id: "Importado", value: "Importado" },
];

const bodyTypeOptions: IOptions[] = [
	{ id: "Conversível", value: "Conversível" },
	{ id: "Coupé", value: "Coupé" },
	{ id: "Hatch", value: "Hatch" },
	{ id: "Minivan", value: "Minivan" },
	{ id: "Perua/SW", value: "Perua/SW" },
	{ id: "Picape", value: "Picape" },
	{ id: "Sedan", value: "Sedan" },
	{ id: "SUV", value: "SUV" },
	{ id: "Van", value: "Van" },
];

const bodyCategoryOptions: IOptions[] = [
	{ id: "Compacto", value: "Compacto" },
	{ id: "Grande", value: "Grande" },
	{ id: "Médio", value: "Médio" },
	{ id: "Leve", value: "Leve" },
	{ id: "Subcompacto", value: "Subcompacto" },
	{ id: "Utilitário", value: "Utilitário" },
];

const booleanOptions: IOptions[] = [
	{ id: "Sim", value: "Sim" },
	{ id: "Não", value: "Não" },
];

export const modelAttributes = {
	cilindersDistribuitionOptions: cilindersDistribuitionOptions,
	engineFeedingOptions: engineFeedingOptions,
	fuelOptions: fuelOptions,
	trasnmissionOptions: trasnmissionOptions,
	originOptions: originOptions,
	bodyTypeOptions: bodyTypeOptions,
	bodyCategoryOptions: bodyCategoryOptions,
	booleanOptions: booleanOptions,
};
