import SentimentVeryDissatisfied from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfied from "@mui/icons-material/SentimentVerySatisfied";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid2";
import Step from "@mui/material/Step/Step";
import StepLabel from "@mui/material/StepLabel/StepLabel";
import Stepper from "@mui/material/Stepper/Stepper";
import Typography from "@mui/material/Typography/Typography";
import Zoom from "@mui/material/Zoom/Zoom";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFormStep } from "../../../types/FormStep.types";
import { CompanySignUpForm } from "./CompanySignUp";
import { UserSignUpForm } from "./UserSignUp";

export default function SignUp() {
	const [activeStep, setActiveStep] = useState(0);
	const [companyTaxIdContext, setCompanyTaxIdContext] = useState<string>("");
	const [succefullyCreatedAccounts, setSuccefullyCreatedAccounts] =
		useState<boolean>(true);

	let navigate = useNavigate();

	const handleNext = () => {
		if (activeStep === steps.length - 1) {
			handleReset();
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const steps: IFormStep[] = [
		{
			title: "Cadastrar Empresa",
			required: true,
			component: (
				<CompanySignUpForm
					companySetter={setCompanyTaxIdContext}
					nextStepHandler={handleNext}
				/>
			),
		},
		{
			title: "Criar Usuário",
			required: true,
			component: (
				<UserSignUpForm
					nextStepHandler={handleNext}
					companyTaxId={companyTaxIdContext}
					succefullyCreatedCallback={setSuccefullyCreatedAccounts}
				/>
			),
		},
	];

	// const handleBack = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep - 1);
	// };

	const handleReset = () => {
		if (succefullyCreatedAccounts) {
			navigate("login");
		} else {
			setActiveStep(0);
			setSuccefullyCreatedAccounts(true);
		}
	};

	return (
		<Box>
			{succefullyCreatedAccounts && (
				<>
					<Grid size={{ xs: 12 }}>
						<h1 style={{ color: "#ff7221" }}>COMECE AGORA</h1>
					</Grid>

					<Stepper alternativeLabel activeStep={activeStep}>
						{steps.map((s: IFormStep) => {
							const stepProps: { completed?: boolean } = {};
							return (
								<Step key={s.title} {...stepProps}>
									<StepLabel>{s.title}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				</>
			)}
			{activeStep === steps.length ? (
				<React.Fragment>
					{succefullyCreatedAccounts ? (
						<>
							<Typography
								variant="h4"
								sx={{ mt: 2, mb: 1, color: "#ff7221" }}
							>
								Cadastro realizado com sucesso!
							</Typography>
							<Zoom
								in={activeStep === steps.length}
								style={{
									transitionDelay:
										activeStep === steps.length
											? "500ms"
											: "0ms",
								}}
							>
								{/* <CompanyCreated /> */}
								<SentimentVerySatisfied
									fontSize="large"
									color="primary"
								/>
							</Zoom>
							<Typography
								variant="h6"
								sx={{ mt: 2, mb: 1, color: "#ff7221" }}
							>
								Realize o Login para acessar a plataforma.
							</Typography>
						</>
					) : (
						<>
							<Typography
								variant="h4"
								sx={{ mt: 2, mb: 1, color: "#ffffff" }}
							>
								Algo deu errado...
							</Typography>
							<Zoom
								in={activeStep === steps.length}
								style={{
									transitionDelay:
										activeStep === steps.length
											? "500ms"
											: "0ms",
								}}
							>
								{/* <CompanyCreated /> */}
								<SentimentVeryDissatisfied
									fontSize="large"
									htmlColor="#ffffff"
								/>
							</Zoom>
							<Typography
								variant="h6"
								sx={{ mt: 2, mb: 1, color: "#ffffff" }}
							>
								Parece que esta empresa já possui um cadastro.
								Entre em contato com o Administrador da Empresa
								ou com o suporte da plataforma.
							</Typography>
						</>
					)}

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							pt: 2,
						}}
					>
						<Box sx={{ flex: "1 1 auto" }} />
						<Button
							variant="contained"
							color="primary"
							onClick={handleReset}
						>
							{succefullyCreatedAccounts
								? "Realizar Login"
								: "Voltar ao início"}
						</Button>
					</Box>
				</React.Fragment>
			) : (
				<React.Fragment>{steps[activeStep].component}</React.Fragment>
			)}
		</Box>
	);
}
