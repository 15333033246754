import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField/TextField";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Copyright } from "../../components/Copyright";
import { authenticate } from "../../services/api/users";

function LoginForm() {
	let navigate = useNavigate();

	const handleLogin = useCallback(
		async (event: any) => {
			event.preventDefault();
			const { email, password } = event.target.elements;
			const user = {
				email: email.value,
				password: password.value,
			};
			try {
				await authenticate(user.email, user.password);
				navigate("/orders");
			} catch (error: any) {
				console.error(error);
			}
		},
		[navigate]
	);

	return (
		<form onSubmit={handleLogin}>
			<Grid container spacing={3}>
				<Grid size={{ xs: 12 }}>
					<TextField
						required
						fullWidth
						variant="standard"
						id="email"
						label="Email do Usuário"
						name="email"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid size={{ xs: 12 }}>
					<TextField
						required
						fullWidth
						variant="standard"
						name="password"
						label="Senha"
						type="password"
						id="password"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
			</Grid>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				style={{ marginTop: "16px" }}
			>
				ENTRAR
			</Button>
			<Grid container justifyContent="flex-end">
				<Grid>
					<br />
					<Link style={{ color: "#ff7221" }} to="/">
						Não possui uma conta? Cadastre-se.
					</Link>
				</Grid>
			</Grid>
		</form>
	);
}

function Login() {
	return (
		<div>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: "98vh" }}
			>
				<Grid size={{ xs: 3 }}>
					<h1 style={{ color: "#ff7221" }}>Login</h1>
				</Grid>

				<Grid size={{ xs: 3 }}>
					<LoginForm />
				</Grid>
			</Grid>
			<Copyright />
		</div>
	);
}

export { Login };
