import {
	addDoc,
	collection,
	getDocs,
	limit,
	orderBy,
	query,
	where,
} from "firebase/firestore";
import { Collections } from "../../Collections";
import { IKeyNumberEdition } from "../../types/KeyNumbers.types";
import { db, pricesRef } from "../firestore";

async function createPriceDoc(price: IKeyNumberEdition) {
	try {
		await addDoc(collection(db, Collections.prices), price);
	} catch (error) {
		console.error(error);
	}
}

async function getPrices() {
	try {
		var q = query(pricesRef, orderBy("createdAt", "desc"), limit(10));
		const dataSnapshot = await getDocs(q);
		const prices = dataSnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				author: data.author,
				costValue: data.costValue,
				createdAt: data.createdAt,
				contributionMargin: data.contributionMargin,
				sellPrice: data.sellPrice,
			} as IKeyNumberEdition;
		});
		return prices;
	} catch (error) {
		console.error(error);
	}
}

async function getCurrentPrice(companyTaxId: string) {
	try {
		var q = query(
			pricesRef,
			where("company", "==", companyTaxId),
			orderBy("createdAt", "desc"),
			limit(1)
		);
		var dataSnapshot = await getDocs(q);

		const price = dataSnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				author: data.author,
				costValue: data.costValue,
				createdAt: data.createdAt,
				contributionMargin: data.contributionMargin,
				sellPrice: data.sellPrice,
				company: data.company,
			} as IKeyNumberEdition;
		});

		return price[0];
	} catch (error) {
		console.error(error);
	}
}

export { createPriceDoc, getCurrentPrice, getPrices };
